import React from 'react'
import "./TurnOffMpin.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import TurnOnMPIN2 from "../../../Assets/Learn/TurnOnMPIN2.webp";
import TurnOffMPIN3 from "../../../Assets/Learn/TurnOffMPIN3.webp";
import TurnOffMPIN4 from "../../../Assets/Learn/TurnOffMPIN4.webp";
import TurnOffMPIN5 from "../../../Assets/Learn/TurnOffMPIN5.webp";
const TurnOffMpin = (props) => {
    return (
        <div className="turn-off-mpin-main-container">
            <div className="turn-off-mpin-breadcrumb">
                <p>Profile</p>
                <p> &gt; </p>
                <p>Turn Off MPIN</p>
            </div>
            <div className="turn-off-mpin-title-div">
                <p>How to Turn Off MPIN in Best Budget</p>
            </div>
            <div className="turn-off-mpin-section-container1">
                <div className="turn-off-mpin-section-container1-description">
                    <ol>
                        <li>
                            Tap on the menu option (represented by a profile icon) located at
                            the top right corner of the Dashboard. Next, from the dropdown,
                            tap on the <span>'Settings'</span> option.
                        </li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={EditProfileDet1} alt="" />
                        </div>
                        <li>In the Settings menu, tap on the <span>'Configure MPIN'</span> option to open the MPIN configuration settings.</li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN2} alt="" />
                        </div>
                        <li>
                            To disable the MPIN, tap on the <span>'Turn Off'</span> option. This will remove the requirement of the MPIN when accessing the Best Budget app.
                        </li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={TurnOffMPIN3} alt="" />
                        </div>
                        <li>
                            Tap on <span>'Proceed'</span> to continue.
                        </li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={TurnOffMPIN4} alt="" />
                        </div>

                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={TurnOffMPIN5} alt="" />
                        </div>

                    </ol>
                </div>
            </div>

            <div className="learn-go-to-top">
                <div
                    className="go-to-page-top"
                    onClick={() => {
                        props.scrollToTop();
                    }}
                ></div>
            </div>
        </div>
    )
}

export default TurnOffMpin