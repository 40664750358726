import React from "react";
import "./SearchPayee.css";
import AddPayees1 from "../../../Assets/Learn/AddPayees1.webp";
import AddPayees2 from "../../../Assets/Learn/AddPayees2.webp";
import SearchPayee3 from "../../../Assets/Learn/SearchPayee3.webp";
import SearchPayee4 from "../../../Assets/Learn/SearchPayee4.webp";
const SearchPayee = (props) => {
  return (
    <div className="search-payee-main-container">
      <div className="search-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Search Payee</p>
      </div>
      <div className="search-payee-title-div">
        <p>How to search for a payee in Best Budget</p>
      </div>
      <div className="search-payee-section-container1">
        <div className="search-payee-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'More'</span> icon in the navigation bar and select the
              <span>'Payees'</span> option.
            </li>
            <div className="search-payee-section-container1-img1-div">
              <img src={AddPayees1} alt="" />
            </div>
            <div className="search-payee-section-container1-img1-div">
              <img src={AddPayees2} alt="" />
            </div>
            <li>
              Tap on the Search field positioned at the top of the screen to
              begin your search. This will open the keyboard and allow you to
              enter a search term.
            </li>
            <div className="search-payee-section-container1-img4-div">
              <img src={SearchPayee3} alt="" />
            </div>
            <li>
              Type the keyword or name related to the item you are looking for.
            </li>
            <li> Press 'Enter' on keyboard to initiate the search.</li>
            <li>
              The search results will display all relevant items matching your
              keyword. Scroll through the list to find the exact payee you're
              looking for.
            </li>
            <div className="search-payee-section-container1-img4-div">
              <img src={SearchPayee4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default SearchPayee;
