import React from "react";
import "./AddAccount.css";
import AddAccount1 from "../../../Assets/Learn/AddAccount1.webp";
import AddAccount2 from "../../../Assets/Learn/AddAccount2.webp";
import AddAccount3 from "../../../Assets/Learn/AddAccount3.webp";
import AddAccount4 from "../../../Assets/Learn/AddAccount4.webp";
import AddAccount5 from "../../../Assets/Learn/AddAccount5.webp";
import AddAccount6 from "../../../Assets/Learn/AddAccount6.webp";
import AddAccount7 from "../../../Assets/Learn/AddAccount7.webp";
import AddAccount8 from "../../../Assets/Learn/AddAccount8.webp";
const AddAccount = (props) => {
  return (
    <div className="add-account-main-container">
      <div className="add-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Add Account</p>
      </div>
      <div className="add-account-title-div">
        <p>How to add an account (non-sync) in Best Budget</p>
      </div>
      <div className="add-account-section-container1">
        <div className="add-account-section-container1-description">
          <ol type="1">
            <li>
              Navigate to <span>'Accounts'</span> tab and tap on <span>'Add accounts'</span> (represented
              by plus symbol) icon located at the top right.
            </li>
            <div className="add-account-section-container1-img1-div">
              <img src={AddAccount1} alt="" />
            </div>
            <li>
              Provide a name that clearly identifies your actual bank account,
              such as "Main Savings" or "SBI Checking".
            </li>
            <div className="add-account-section-container1-img2-div">
              <img src={AddAccount2} alt="" />
            </div>
            <li>
              Select the account type from the provided options. Best Budget
              offers three account types to choose from:
              <br />
              <strong>Budget Accounts:</strong> For managing your day-to-day
              finances, including cash, digital wallets, and bank accounts.
              <br />
              <strong>Non Budget Accounts:</strong> For tracking your long-term
              financial goals, such as retirement savings, stocks, assets,
              liabilities and real estate.
              <br />
              <strong>Loans & Mortgage Accounts:</strong> For keeping track of
              your loans and mortgage payments to stay on top of your debt
              obligations.
            </li>
            <div className="add-account-section-container1-img3-div">
              <img src={AddAccount3} alt="" />
            </div>
            <li>
              The 'sign indicator' will change to positive or negative based
              on the account type you select.
              <br /> Positive account types include all budget accounts (except
              for Credit Cards), non-budget accounts like Investments, Assets,
              and Retirement. Negative account types include Credit Cards,
              Liabilities, and Mortgage accounts.
            </li>
            <li>
              {" "}
              You can also manually toggle between positive and negative balance
              using the 'Sign indicator'.
            </li>
            <li>
              Input your current bank account balance.This will be set as the
              initial balance for your account in Best Budget.
            </li>
            <div className="add-account-section-container1-img4-div">
              <img src={AddAccount4} alt="" />
            </div>
            <div className="add-account-section-container1-img5-div">
              <img src={AddAccount5} alt="" />
            </div>
            <li>
              You can use ‘Account Notes’ to enter any information related to
              the account, like Branch, IFSC Codes and any other details.
            </li>
            <li>
              Finally, tap on the <span>'Save'</span> button to create your new account.
            </li>
            <div className="add-account-section-container1-img6-div">
              <img src={AddAccount6} alt="" />
            </div>
            <li>
              All your accounts will be grouped and organised into three
              sections based on their type: <span>Budget, Investment & Others,</span> and
              <span>Loans & Mortgage</span>.
            </li>
            <li>
              Once the account is added successfully, you
              will notice that the <span>'Available to Spend'</span> is changed from ₹0.00 to
              the total of all positive valued budget accounts.
            </li>
            <div className="add-account-section-container1-img6-div">
              <img src={AddAccount7} alt="" />
            </div>
            <div className="add-account-section-container1-img6-div">
              <img src={AddAccount8} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-add-account-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddAccount;
