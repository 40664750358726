import React from "react";
import "./DeleteAccount.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import DeleteAccount2 from "../../../Assets/Learn/DeleteAccount2.webp";
import DeleteAccount3 from "../../../Assets/Learn/DeleteAccount3.webp";
import DeleteAccount4 from "../../../Assets/Learn/DeleteAccount4.webp";
const DeleteAccount = (props) => {
  return (
    <div className="delete-account-main-container">
      <div className="delete-account-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Delete Account</p>
      </div>
      <div className="delete-account-title-div">
        <p>How to delete your account in Best Budget</p>
      </div>
      <div className="delete-account-section-container1">
        <div className="delete-account-section-container1-description">
          <ol>
            <li>
              Tap on the menu option (represented by a profile icon) located at
              the top right corner of the Dashboard. Next, from the dropdown,
              tap on the <span>'Settings'</span> option.
            </li>
            <div className="delete-account-section-container1-img4-div">
              <img src={EditProfileDet1} alt="" />
            </div>
            <li>Tap on the <span>'Delete Account'</span> option.</li>
            <div className="delete-account-section-container1-img4-div">
              <img src={DeleteAccount2} alt="" />
            </div>
            <li>
              Tap on the <span>'Delete Account'</span> button to proceed with deleting the
              account.
            </li>
            <div className="delete-account-section-container1-img4-div">
              <img src={DeleteAccount3} alt="" />
            </div>
            <li>
              After deleting your account, you'll automatically be redirected to
              starter page.
            </li>
            <div className="delete-account-section-container1-img4-div">
              <img src={DeleteAccount4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteAccount;
