import React from "react";
import "./EditCategory.css";
import EditCategory1 from "../../../Assets/Learn/EditCategory1.webp";
import EditCategory2 from "../../../Assets/Learn/EditCategory2.webp";
import EditCategory3 from "../../../Assets/Learn/EditCategory3.webp";
import EditCategory4 from "../../../Assets/Learn/EditCatGroup4.webp";
const EditCategory = (props) => {
  return (
    <div className="edit-category-main-container">
      <div className="edit-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Edit Category</p>
      </div>
      <div className="edit-category-title-div">
        <p>How to edit a category in Best Budget</p>
      </div>
      <div className="edit-category-section-container1">
        <div className="edit-category-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="edit-category-section-container1-img1-div">
              <img src={EditCategory1} alt="" />
            </div>
            <li>
              Locate the category you wish to edit. Tap on it to enable editing
              mode.
            </li>
            <div className="edit-category-section-container1-img1-div">
              <img src={EditCategory2} alt="" />
            </div>
            <li>
              Modify the name of the category as desired to better reflect its
              purpose or contents.
            </li>
            <li>
              After making your changes, tap on the <span>'SAVE'</span> button to save the
              updated category name. <br /> <strong>Note:</strong> You can add
              new category groups and categories, edit existing ones, and save
              all changes at once by tapping on the SAVE button. This feature
              allows for efficient management of your categories without the
              need to save each change individually.
            </li>
            <div className="edit-category-section-container1-img1-div">
              <img src={EditCategory3} alt="" />
            </div>
            <div className="edit-category-section-container1-img1-div">
              <img src={EditCategory4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditCategory;
