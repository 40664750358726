import React from "react";
import "./AddPayee.css";
import AddPayees1 from "../../../Assets/Learn/AddPayees1.webp";
import AddPayees2 from "../../../Assets/Learn/AddPayees2.webp";
import AddPayee3 from "../../../Assets/Learn/AddPayee3.webp";
import AddPayee4 from "../../../Assets/Learn/AddPayee4.webp";
import AddPayee5 from "../../../Assets/Learn/AddPayee5.webp";
import AddPayee6 from "../../../Assets/Learn/AddPayee6.webp";
import AddPayee7 from "../../../Assets/Learn/AddPayee7.webp";
const AddPayee = (props) => {
  return (
    <div className="add-payee-main-container">
      <div className="add-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Add a Payee</p>
      </div>
      <div className="add-payee-title-div">
        <p>How to add a new payee in Best Budget</p>
      </div>
      <div className="add-payee-section-container1">
        <div className="add-payee-section-container1-description">
          <ol>
            <li>1. Tap on the <span>'More'</span> icon in the Bottom Navigation and tap on the <span>'Payees'</span> icon</li>
            <div className="add-payee-section-container1-img1-div">
              <img src={AddPayees1} alt="" />
            </div>
            <div className="add-payee-section-container1-img1-div">
              <img src={AddPayees2} alt="" />
            </div>
            <li>
              To create a new ‘Payee’ tap the ‘+’ icon (located at the top right
              corner).
            </li>
            <div className="add-payee-section-container1-img4-div">
              <img src={AddPayee3} alt="" />
            </div>
            <li>
              Enter the name of the payee (e.g., the company or individual you
              make payments to).
            </li>
            <li>
              Specify the default payment direction to either <span>'Inflow’</span> or
              <span>'Outflow'</span> based on the type of transactions (‘Income’ or
              ‘Expense’) you will make with this payee. This will be helpful to
              visually differentiate the Income or Expense payees while creating
              transactions.
            </li>
            <div className="add-payee-section-container1-img4-div">
              <img src={AddPayee4} alt="" />
            </div>
            <li>In the default category field, select a category that best matches the type of transactions you will be making with this payee (e.g., Food, Housing, Salary, etc.). If needed, you can also create a new category directly from this screen without choosing from the existing list.</li>
            <div className="add-payee-section-container1-img4-div">
              <img src={AddPayee5} alt="" />
            </div>
            <li>After entering all the necessary details, tap the <span>'Save'</span> button to save the payee information.</li>
            <div className="add-payee-section-container1-img4-div">
              <img src={AddPayee6} alt="" />
            </div>
            <div className="add-payee-section-container1-img4-div">
              <img src={AddPayee7} alt="" />
            </div>
            <p><strong>Note:</strong> The arrow in red indicated the default direction associated with the ‘Payee’</p>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddPayee;
