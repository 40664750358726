import React from "react";
import "./BudgetSettings.css";
import BudSetting1 from "../../../Assets/Learn/BudSetting1.webp";
import BudSetting2 from "../../../Assets/Learn/BudSetting2.webp";
import BudSetting3 from "../../../Assets/Learn/BudSetting3.webp";
import BudSetting4 from "../../../Assets/Learn/BudSetting4.webp";
import BudSetting5 from "../../../Assets/Learn/BudSetting5.webp";
import BudSetting6 from "../../../Assets/Learn/BudSetting6.webp";
import BudSetting7 from "../../../Assets/Learn/BudSetting7.webp";
import BudSetting8 from "../../../Assets/Learn/BudSetting8.webp";
import BudSetting9 from "../../../Assets/Learn/BudSetting9.webp";
import BudSetting10 from "../../../Assets/Learn/BudSetting10.webp";
import BudSetting11 from "../../../Assets/Learn/BudSetting11.webp";
import BudSetting12 from "../../../Assets/Learn/BudSetting12.webp";
import BudSetting13 from "../../../Assets/Learn/BudSetting13.webp";
import BudSetting14 from "../../../Assets/Learn/BudSetting14.webp";
import BudSetting15 from "../../../Assets/Learn/BudSetting15.webp";
const BudgetSettings = (props) => {
  return (
    <div className="budget-settings-main-container">
      <div className="budget-settings-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Budget Settings</p>
      </div>
      <div className="budget-settings-title-div">
        <p>How to add a new ‘Budget Plan’ in Best Budget</p>
      </div>
      <div className="budget-settings-section-container1">
        <div className="budget-settings-section-container1-description">
          <ol>
            <li>
              On your <span>Best Budget</span> App, open <span>'Dashboard'</span> page and tap on the
              'Current Budget Name’ located at the top left of the screen. This
              will open the ‘Budget Plans’ screen where you can create, edit and
              delete budget plans.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting1} alt="" />
            </div>
            <li>
              Tap on the <span>'Create new Budget plan'</span> button to create a new budget.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting2} alt="" />
            </div>
            <li>
              Enter the desired budget name in the provided field. Choose a name
              that clearly identifies the purpose of the budget for easy
              reference.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting3} alt="" />
            </div>
            <li>
              By default, Best Budget is set to IST standards for currency,
              number and date formats. You can change these settings to suit
              your preferences.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting4} alt="" />
            </div>
            <li>
              <strong>Number Formats:</strong> Select the number format to match
              your comfort (e.g., commas, decimal points).
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting5} alt="" />
            </div>
            <li>
              <strong>Date Formats:</strong> Select a specific date format that
              works best for you.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting6} alt="" />
            </div>
            <li>
              After entering the budget name and other preferences, tap on
              <span>'Proceed'</span> button to save the budget.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting7} alt="" />
            </div>
            <li>
              <span>
                You can see that the new budget is created.
              </span>{" "}
              To switch, just tap on the new budget you have created just now.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting8} alt="" />
            </div>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting9} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="budget-settings-title-div">
        <p>How to edit an existing ‘Budget Plan’ in Best Budget</p>
      </div>
      <div className="budget-settings-section-container1">
        <div className="budget-settings-section-container1-description">
          <ol>
            <li>
              To edit , Tap on the ‘ i ’ icon next to budget plan which will
              open the budget details screen.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting10} alt="" />
            </div>
            <li>
              Click on the name filed to enter to edit mode and change the
              budget name as desired.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting11} alt="" />
            </div>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting12} alt="" />
            </div>
            <li>
              To Delete the Budget Plan , Tap on the ‘ i ’ icon next to budget
              plan which will open the budget details screen.
              <br />
              <strong>Note:</strong> You cannot delete current budget plan when
              it is in use. In order to delete the current budget plan you need
              to switch to any other budget by tapping and making it as current
              one.
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting13} alt="" />
            </div>
            <li>
              Click on the <span>delete button to delete</span>.{" "}
              <span style={{ color: "#A02E2E", fontWeight: "500" }}>
                Be cautious with this action as it can not be undone.
              </span>
            </li>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting14} alt="" />
            </div>
            <div className="budget-settings-section-container1-img1-div">
              <img src={BudSetting15} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default BudgetSettings;
