import React from "react";
import "./EditProfileDetails.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import EditProfileDet2 from "../../../Assets/Learn/EditProfileDet2.webp";
import EditProfileDet3 from "../../../Assets/Learn/EditProfileDet3.webp";
import EditProfileDet4 from "../../../Assets/Learn/EditProfileDet4.webp";
const EditProfileDetails = (props) => {
  return (
    <div className="edit-profile-details-main-container">
      <div className="edit-profile-details-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Edit Profile details</p>
      </div>
      <div className="edit-profile-details-title-div">
        <p>How to edit profile details in Best Budget</p>
      </div>
      <div className="edit-profile-details-section-container1">
        <div className="edit-profile-details-section-container1-description">
          <ol>
            <li>
              Tap on the menu option (represented by a profile icon) located at
              the top right corner of the Dashboard. Next, from the dropdown,
              tap on the <span>'Settings'</span> option.
            </li>
            <div className="edit-profile-details-section-container1-img4-div">
              <img src={EditProfileDet1} alt="" />
            </div>
            <li>In the settings section, locate the <span>'Display name'</span> field and tap on it to enable editing.</li>
            <div className="edit-profile-details-section-container1-img4-div">
              <img src={EditProfileDet2} alt="" />
            </div>
            <li> Enter the desired name in the display name field and tap on the <span>'UPDATE'</span> button to save the changes.</li>
            <div className="edit-profile-details-section-container1-img4-div">
              <img src={EditProfileDet3} alt="" />
            </div>
            <div className="edit-profile-details-section-container1-img4-div">
              <img src={EditProfileDet4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditProfileDetails;
