import React from "react";
import "./IndividualAllocation.css";
import IndividualAllocation1 from "../../../Assets/Learn/IndividualAllocation1.webp";
import IndividualAllocation2 from "../../../Assets/Learn/IndividualAllocation2.webp";
import IndividualAllocation3 from "../../../Assets/Learn/IndividualAllocation3.webp";
const IndividualAllocation = (props) => {
  return (
    <div className="individual-allocation-main-container">
      <div className="individual-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Individual Allocation</p>
      </div>
      <div className="individual-allocation-title-div">
        <p>How to allocate amount to a category in Best Budget</p>
      </div>
      <div className="individual-allocation-section-container1">
        <div className="individual-allocation-section-container1-description">
          <ol>
            <li>
              To allocate or modify the allocated amount for each budget
              category, tap on the 'allocated' section. This will allow you to
              easily adjust your spending limits and ensure that your budget
              aligns with your financial goals.
            </li>
            <div className="individual-allocation-section-container1-img1-div">
              <img src={IndividualAllocation1} alt="" />
            </div>
            <li>
              Allocate the desired amount and click on 'Enter' on keyboard to
              save your allocation.
            </li>
            <div className="individual-allocation-section-container1-img1-div">
              <img src={IndividualAllocation2} alt="" />
            </div>
            <div className="individual-allocation-section-container1-img1-div">
              <img src={IndividualAllocation3} alt="" />
            </div>
          </ol>
        </div>
      </div>


      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default IndividualAllocation;
