import React from "react";
import "./EditPayee.css";
import AddPayees1 from "../../../Assets/Learn/AddPayees1.webp";
import AddPayees2 from "../../../Assets/Learn/AddPayees2.webp";
import EditPayee3 from "../../../Assets/Learn/EditPayee3.webp";
import EditPayee4 from "../../../Assets/Learn/EditPayee4.webp";
import EditPayee5 from "../../../Assets/Learn/EditPayee5.webp";
import EditPayee6 from "../../../Assets/Learn/EditPayee6.webp";
const EditPayee = (props) => {
  return (
    <div className="edit-payee-main-container">
      <div className="edit-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Edit Payee</p>
      </div>
      <div className="edit-payee-title-div">
        <p>How to edit payee in Best Budget</p>
      </div>
      <div className="edit-payee-section-container1">
        <div className="edit-payee-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'More'</span> icon in the bottom navigation and tap the
              <span>'Payees'</span> icon.
            </li>
            <div className="edit-payee-section-container1-img1-div">
              <img src={AddPayees1} alt="" />
            </div>
            <div className="edit-payee-section-container1-img1-div">
              <img src={AddPayees2} alt="" />
            </div>
            <li>
              Scroll to locate the payee you wish to edit, and tap on the payee
              field to open the payee details.
            </li>
            <div className="edit-payee-section-container1-img4-div">
              <img src={EditPayee3} alt="" />
            </div>
            <li>
              Modify the necessary details such as payee name and default
              payment direction to either <span>Inflow</span> or <span>Outflow</span> based on the type of
              transactions you would create with this payee.
            </li>
            <li>
              Adjust or assign the category that best represents the typical
              transactions with this payee (e.g., Food, Housing, Bills). You can
              also add a new category if none of the current options fit.
            </li>
            <div className="edit-payee-section-container1-img4-div">
              <img src={EditPayee4} alt="" />
            </div>
            <li>
              Tap on the <span>'Save'</span> button to save the updated payee information.
            </li>
            <div className="edit-payee-section-container1-img4-div">
              <img src={EditPayee5} alt="" />
            </div>
            <div className="edit-payee-section-container1-img4-div">
              <img src={EditPayee6} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditPayee;
