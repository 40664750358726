import React from "react";
import "./RearrangeCategories.css";
import RearrangeCategories1 from "../../../Assets/Learn/RearrangeCategories1.webp";
import RearrangeCategories2 from "../../../Assets/Learn/RearrangeCategories2.webp";
import RearrangeCategories3 from "../../../Assets/Learn/RearrangeCategories3.webp";
import RearrangeCategories4 from "../../../Assets/Learn/RearrangeCategories4.webp";
import RearrangeCategories5 from "../../../Assets/Learn/RearrangeCategories5.webp";
import RearrangeCategories6 from "../../../Assets/Learn/RearrangeCategories6.webp";
import RearrangeCategories7 from "../../../Assets/Learn/RearrangeCategories7.webp";
import RearrangeCategories8 from "../../../Assets/Learn/RearrangeCategories8.webp";
import RearrangeCategories9 from "../../../Assets/Learn/RearrangeCategories9.webp";
const RearrangeCategories = (props) => {
  return (
    <div className="rearrange-categories-main-container">
      <div className="rearrange-categories-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Re-Arrange Categories</p>
      </div>
      <div className="rearrange-categories-title-div">
        <p>Rearranging categories within the category group in Best Budget</p>
      </div>
      <div className="rearrange-categories-section-container1">
        <div className="rearrange-categories-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'rearrange'</span> icon (represented by 4 horizontal parallel
              lines) positioned at the top right.
            </li>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories1} alt="" />
            </div>
            <li>Locate the desired category you wish to rearrange.</li>
            <li>
              Tap on the rearrange icon positioned towards the right of the
              category.
            </li>
            <li>
              Hold and drag the category to the desired position within the same
              category group.
            </li>
            <div className="rearrange-categories-section-container1-img2-div">
              <img src={RearrangeCategories2} alt="" />
            </div>
            <div className="rearrange-categories-section-container1-img2-div">
              <img src={RearrangeCategories3} alt="" />
            </div>
            <li>Alternatively, you can also rearrange the category groups.</li>
            <li>
              Tap and hold on the category group you wish to rearrange. All the
              categories of all groups will be minimized.
            </li>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories4} alt="" />
            </div>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories5} alt="" />
            </div>
            <li>Move the category group to the desired position.</li>
            <li>
              Alternatively, Tap and hold on the category group to expand the
              categories, to rearrange them.
            </li>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories6} alt="" />
            </div>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories7} alt="" />
            </div>
            <li>
              Tap on 'Done' icon (represented by tick mark) located at the
              top right corner to save the changes.
            </li>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories8} alt="" />
            </div>
            <div className="rearrange-categories-section-container1-img1-div">
              <img src={RearrangeCategories9} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default RearrangeCategories;
