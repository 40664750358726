import React from "react";
import "./Allocations.css";
import Summary1 from "../../../Assets/Learn/Summary1.webp";
import Allocations2 from "../../../Assets/Learn/Allocations2.webp";
import Allocations3 from "../../../Assets/Learn/Allocations3.webp";
import Allocations4 from "../../../Assets/Learn/Allocations4.webp";
import Allocations5 from "../../../Assets/Learn/Allocations5.webp";
const Allocations = (props) => {
  return (
    <div className="allocations-main-container">
      <div className="allocations-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Allocations</p>
      </div>
      <div className="allocations-title-div">
        <p>Accessing 'Allocations' in dashboard</p>
      </div>
      <div className="allocations-section-container1">
        <div className="allocations-section-container1-description">
          <ol>
            <li>
              Tap on the 'Dashboard' icon located in the bottom navigation bar
              and open Dashboard..
            </li>
            <div className="allocations-section-container1-img1-div">
              <img src={Summary1} alt="" />
            </div>
            <li>
              By default, the <span>'Summary'</span> view is displayed when you enter the
              Dashboard.
            </li>
            <li>
              To view the <span>'Allocations'</span>, tap on the left arrow at
              the bottom of the screen. The Budget <span>'Allocations'</span> section
              provides a visual representation of your spending across different
              categories groups and categories.
            </li>
            <div className="allocations-section-container1-img3-div">
              <img src={Allocations2} alt="" />
            </div>
            <li>Tap on the desired category group to view the allocations for each category within that group.</li>
            <div className="allocations-section-container1-img3-div">
              <img src={Allocations3} alt="" />
            </div>
            <li>Use the right and left arrows to switch between categories of different category groups.</li>
            <div className="allocations-section-container1-img3-div">
              <img src={Allocations4} alt="" />
            </div>
            <li>To return to the view showing all category groups, tap the 'Back' icon located at the top left.</li>
            <div className="allocations-section-container1-img3-div">
              <img src={Allocations5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Allocations;
