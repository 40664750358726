import React from "react";
import "./AddCateGroup.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import AddCatGroup2 from "../../../Assets/Learn/AddCatGroup2.webp";
import AddCatGroup3 from "../../../Assets/Learn/AddCatGroup3.webp";
import AddCatGroup4 from "../../../Assets/Learn/AddCatGroup4.webp";
import AddCatGroup5 from "../../../Assets/Learn/AddCatGroup5.webp";
const AddCateGroup = (props) => {
  return (
    <div className="add-cate-group-main-container">
      <div className="add-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Add Category Group</p>
      </div>
      <div className="add-cate-group-title-div">
        <p>How to add a category group in Best Budget</p>
      </div>
      <div className="add-cate-group-section-container1">
        <div className="add-cate-group-section-container1-description">
          <ol>
            <li>
              Navigate to <span>'Budget'</span> tab and tap on <span>'Manage Categories'</span> icon
              (represented by a pencil shape) located on the top right corner.
            </li>
            <div className="add-cate-group-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Tap on the <span>'Add Category group'</span> icon (represented by a folder
              shape with a plus symbol) located at the top right.
            </li>
            <div className="add-cate-group-section-container1-img1-div">
              <img src={AddCatGroup2} alt="" />
            </div>
            <li>
              A new row is added for the category group. Choose a name that
              clearly defines the group’s purpose .
            </li>
            <div className="add-cate-group-section-container1-img1-div">
              <img src={AddCatGroup3} alt="" />
            </div>
            <li>
              Tap on <span>'SAVE'</span> button to save your new category group.
              <br />
              <strong>Note:</strong> You can add new category groups,
              categories, edit existing ones, and save all the changes at once
              by tapping the 'SAVE' button.
            </li>
            <div className="add-cate-group-section-container1-img1-div">
              <img src={AddCatGroup4} alt="" />
            </div>
            <div className="add-cate-group-section-container1-img1-div">
              <img src={AddCatGroup5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddCateGroup;
