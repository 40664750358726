import React from "react";
import "./AddExpense.css";
import IncomeTrans1 from "../../../Assets/Learn/IncomeTrans1.webp";
import IncomeTrans10 from "../../../Assets/Learn/IncomeTrans10.webp";
import ExpenseTransaction2 from "../../../Assets/Learn/ExpenseTransaction2.webp";
import ExpenseTransaction3 from "../../../Assets/Learn/ExpenseTransaction3.webp";
import ExpenseTransaction4 from "../../../Assets/Learn/ExpenseTransaction4.webp";
import ExpenseTransaction5 from "../../../Assets/Learn/ExpenseTransaction5.webp";
import ExpenseTransaction6 from "../../../Assets/Learn/ExpenseTransaction6.webp";
import ExpenseTransaction7 from "../../../Assets/Learn/ExpenseTransaction7.webp";
import ExpenseTransaction9 from "../../../Assets/Learn/ExpenseTransaction9.webp";
import ExpenseTransaction10 from "../../../Assets/Learn/ExpenseTransaction10.webp";
import ExpenseTransaction11 from "../../../Assets/Learn/ExpenseTransaction11.webp";
import ExpenseTransaction12 from "../../../Assets/Learn/ExpenseTransaction12.webp";
import IncomeTrans15 from "../../../Assets/Learn/IncomeTrans15.webp";
import ExpenseTransaction14 from "../../../Assets/Learn/ExpenseTransaction14.webp";
import ExpenseTransaction15 from "../../../Assets/Learn/ExpenseTransaction15.webp";
import ExpenseTransaction16 from "../../../Assets/Learn/ExpenseTransaction16.webp";
import ExpenseTransaction17 from "../../../Assets/Learn/ExpenseTransaction17.webp";

const AddExpense = (props) => {
  return (
    <div className="add-expense-main-container">
      <div className="add-expense-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Add Expense</p>
      </div>
      <div className="add-expense-title-div">
        <p>How to add an expense transaction in Best Budget</p>
      </div>
      <div className="add-expense-section-container1">
        <div className="add-expense-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Transaction'</span> icon located in the navigation bar at the
              bottom of the screen. This will open the transaction creation page
              where you can add your new transaction.
            </li>
            <div className="add-expense-section-container1-img1-div">
              <img src={IncomeTrans1} alt="" />
            </div>
            <li>
              By default, the <span>'Expense'</span> transaction type will be selected.
            </li>
            <li>
              In the <span>Transaction Amount</span> field, input the amount of expense you
              have made. This is the amount that will be recorded for this
              transaction.
            </li>
            <li>Choose the <span>Transaction Status</span> for the expense transaction:</li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction2} alt="" />
            </div>
            <li>
              Tap on the <span>'Payee'</span> field. Here, you can either select an existing
              payee from the list or create a new one. To create a new payee,
              tap on <span>'Add New Payee'</span> icon (represented by plus symbol) on top
              right corner and enter the necessary details (such as name or
              business).
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction3} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction4} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction5} alt="" />
            </div>
            <li>
              Tap on the <span>'Category'</span> field and choose the relevant category for
              your expense. Here, you can either select an existing category
              from the list or if you don’t find an appropriate category, tap on
              <span>'Add New category'</span> icon (represented by plus symbol) on top right
              corner and enter the necessary details.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction6} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction7} alt="" />
            </div>
            <li>
              Tap on the <span>'Account'</span> field and select the desired account for the
              transaction.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={IncomeTrans10} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction9} alt="" />
            </div>
            <li>
              Tap on the <span>'Date'</span> field and choose the date for the transaction.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction10} alt="" />
            </div>
            <li>
              Tap on the 'Memo' field to enter any notes about the transaction
              (optional). This could be a description or additional details that
              can help you remember the source or purpose of the transaction.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction11} alt="" />
            </div>
            <li>
              You have the flexibility to schedule transactions for future
              processing, allowing you to plan your finances in advance.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction12} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={IncomeTrans15} alt="" />
            </div>
            <li>
              You can also set reminders for your scheduled transactions. These
              reminders will notify you before the transaction is processed,
              ensuring you’re always aware of your upcoming financial
              activities.
              <br /> <strong>Note:</strong>This field will appear only when you
              set recurring frequency.
            </li>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction14} alt="" />
            </div>
            <div className="add-expense-section-container1-img2-div">
              <img src={ExpenseTransaction15} alt="" />
            </div>
            <li>
              Once all the details have been filled out, tap the <span>'Save'</span> button
              located at the bottom of the screen to finalize and save the
              transaction.
            </li>
            <div className="add-expense-section-container1-img3-div">
              <img src={ExpenseTransaction16} alt="" />
            </div>
            <div className="add-expense-section-container1-img3-div">
              <img src={ExpenseTransaction17} alt="" />
            </div>
            <p>
              <strong>Note:</strong> When creating a new transaction, the app will automatically
              pre-fill the details from the previous transaction (if any) based
              on the transaction type (Income or Expense or Transfer) you are
              creating. You can edit these details as necessary before saving.
              This feature helps streamline the process when dealing with
              similar transactions.
            </p>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddExpense;
