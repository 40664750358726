import React from "react";
import "./MoveCategory.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import MoveCategory2 from "../../../Assets/Learn/MoveCategory2.webp";
import MoveCategory3 from "../../../Assets/Learn/MoveCategory3.webp";
import MoveCategory4 from "../../../Assets/Learn/MoveCategory4.webp";
import MoveCategory5 from "../../../Assets/Learn/MoveCategory5.webp";

const MoveCategory = (props) => {
  return (
    <div className="move-category-main-container">
      <div className="move-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Move Category</p>
      </div>
      <div className="move-category-title-div">
        <p>How to move a category from one category group to another</p>
      </div>
      <div className="move-category-section-container1">
        <div className="move-category-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="move-category-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Locate the desired category group you wish to move to another
              category group.
            </li>
            <li>
              Look for the <span>'Menu'</span> icon (represented by minus symbol) positioned
              near the left side of the category group name.
            </li>
            <li>
              From the available menu options, select <span>'Switch Group'</span>. This will
              prompt a list of category groups to appear.
            </li>
            <div className="move-category-section-container1-img1-div">
              <img src={MoveCategory2} alt="" />
            </div>
            <li>
              Tap on the category group you wish to move the category into and
              tap on 'Proceed' button. The category will then be transferred to
              the selected group.
            </li>
            <div className="move-category-section-container1-img1-div">
              <img src={MoveCategory3} alt="" />
            </div>
            <p>
              <strong>Note:</strong> All changes made while moving categories between groups are
              automatically saved. You do not need to manually save these
              updates.
            </p>
            <div className="move-category-section-container1-img1-div">
              <img src={MoveCategory4} alt="" />
            </div>
            <div className="move-category-section-container1-img1-div">
              <img src={MoveCategory5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default MoveCategory;
