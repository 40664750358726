import React from "react";
import "./ReopenAccount.css";
import CloseAccount5 from "../../../Assets/Learn/CloseAccount5.webp";
import ReopenAccount2 from "../../../Assets/Learn/ReopenAccount2.webp";
import ReopenAccount3 from "../../../Assets/Learn/ReopenAccount3.webp";
import ReopenAccount4 from "../../../Assets/Learn/ReopenAccount4.webp";
import ReopenAccount5 from "../../../Assets/Learn/ReopenAccount5.webp";
const ReopenAccount = (props) => {
  return (
    <div className="reopen-account-main-container">
      <div className="reopen-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Reopen Account</p>
      </div>
      <div className="reopen-account-title-div">
        <p>How to reopen a closed account in Best Budget</p>
      </div>
      <div className="reopen-account-section-container1">
        <div className="reopen-account-section-container1-description">
          <ol>
            <li>
              Navigate to <span>'Accounts'</span> tab and look for the <span>'Menu'</span> icon in the top
              left corner (represented by three horizontal dots) and Tap on the
              <span>'Menu'</span> icon to reveal additional options.
            </li>
            <li>From the menu options, select <span>'Closed Accounts'</span>.</li>
            <div className="reopen-account-section-container1-img1-div">
              <img src={CloseAccount5} alt="" />
            </div>
            <li>
              This will display a list of all accounts that have been temporarily closed.
            </li>
            <li>Find the account you wish to reactivate and just tap on it to open that account details.</li>
            <div className="reopen-account-section-container1-img1-div">
              <img src={ReopenAccount2} alt="" />
            </div>
            <li>On the detailed page of the closed account, locate and tap the <span>'Reopen'</span> button at the top.</li>
            <div className="reopen-account-section-container1-img1-div">
              <img src={ReopenAccount3} alt="" />
            </div>
            <li>A confirmation window pop-up will appear, select the 'Reopen' option to complete the process.</li>
            <div className="reopen-account-section-container1-img1-div">
              <img src={ReopenAccount4} alt="" />
            </div>
            <li>The account will be reopened with a <span>zero balance</span> as the initial amount.</li>
            <li>If necessary, adjust the balance by selecting <span>'Edit Account'</span> and update the ‘Current Balance’ accordingly.</li>
            <div className="reopen-account-section-container1-img1-div">
              <img src={ReopenAccount5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ReopenAccount;
