import React from "react";
import "./TurnOnMpin.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import TurnOnMPIN2 from "../../../Assets/Learn/TurnOnMPIN2.webp";
import TurnOnMPIN3 from "../../../Assets/Learn/TurnOnMPIN3.webp";
import TurnOnMPIN4 from "../../../Assets/Learn/TurnOnMPIN4.webp";
import TurnOnMPIN5 from "../../../Assets/Learn/TurnOnMPIN5.webp";
import TurnOnMPIN6 from "../../../Assets/Learn/TurnOnMPIN6.webp";
const TurnOnMpin = (props) => {
    return (
        <div className="turn-on-mpin-main-container">
            <div className="turn-on-mpin-breadcrumb">
                <p>Profile</p>
                <p> &gt; </p>
                <p>Turn On MPIN</p>
            </div>
            <div className="turn-on-mpin-title-div">
                <p>How to Turn On MPIN in Best Budget</p>
            </div>
            <div className="turn-on-mpin-section-container1">
                <div className="turn-on-mpin-section-container1-description">
                    <ol>
                        <li>
                            Tap on the menu option (represented by a profile icon) located at
                            the top right corner of the Dashboard. Next, from the dropdown,
                            tap on the <span>'Settings'</span> option.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={EditProfileDet1} alt="" />
                        </div>
                        <li>Tap on the <span>'Configure MPIN'</span> option.</li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN2} alt="" />
                        </div>
                        <li>
                            Select 'Turn on' option to set up an MPIN (Mobile Personal
                            Identification Number) for enhanced security while using the Best
                            Budget app.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN3} alt="" />
                        </div>
                        <li>
                            Choose a 4-digit PIN that you can easily remember but is secure
                            enough to protect your account. Enter this PIN in the provided
                            field.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN4} alt="" />
                        </div>
                        <li>
                            After confirming the PIN, Tap on the 'Save' button to store your
                            new MPIN. This will enable additional security for your app
                            access.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN5} alt="" />
                        </div>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN6} alt="" />
                        </div>
                    </ol>
                </div>
            </div>

            <div className="learn-go-to-top">
                <div
                    className="go-to-page-top"
                    onClick={() => {
                        props.scrollToTop();
                    }}
                ></div>
            </div>
        </div>
    );
};

export default TurnOnMpin;
