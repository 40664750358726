import React from "react";
import "./ReconcileAccount.css";
import ReconcileAccount1 from "../../../Assets/Learn/ReconcileAccount1.webp";
import ReconcileAccount2 from "../../../Assets/Learn/ReconcileAccount2.webp";
import ReconcileAccount3 from "../../../Assets/Learn/ReconcileAccount3.webp";
import ReconcileAccount4 from "../../../Assets/Learn/ReconcileAccount4.webp";
import ReconcileAccount5 from "../../../Assets/Learn/ReconcileAccount5.webp";
import ReconcileAccount6 from "../../../Assets/Learn/ReconcileAccount6.webp";
import ReconcileAccount7 from "../../../Assets/Learn/ReconcileAccount7.webp";
import ReconcileAccount8 from "../../../Assets/Learn/ReconcileAccount8.webp";

const ReconcileAccount = (props) => {
  return (
    <div className="reconcile-account-main-container">
      <div className="reconcile-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Reconcile Account</p>
      </div>
      <div className="reconcile-account-title-div">
        <p>How to reconcile an account in Best Budget</p>
      </div>
      <div className="reconcile-account-section-container1">
        <div className="reconcile-account-section-container1-description">
          <ol>
            <li>
              Navigate to <span>'Accounts'</span> tab and navigate to the detailed page of
              your desired account. Tap on the <span>'Menu'</span> icon (represented by 3
              horizontal dots) located in the top right corner of the screen to
              reveal additional options.
            </li>
            <li>From the drop-down menu, select <span>'Reconcile'</span>.</li>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount1} alt="" />
            </div>
            <li>
              If you want to clear all pending transactions and proceed with the
              reconciliation, tap on the <span>'Proceed'</span> button. This action will mark
              all the pending transactions as cleared.
            </li>
            <li>
              If you prefer to review them individually, select <span>'Cancel'</span> and
              carefully review and mark each transaction as cleared, manually.
            </li>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount2} alt="" />
            </div>
            <li>
              The subsequent pop-up window shows the balance of your account as
              displayed in Best Budget.
            </li>
            <li>
              Check to see if this balance accurately reflects your actual bank
              balance. If the displayed balance matches your actual account
              balance, tap on <span>'Yes'</span>. and your account will be reconciled
              directly. If the balance doesn't match, tap on <span>'No'</span>.
            </li>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount3} alt="" />
            </div>
            <li>
              If you select <span>'No'</span>, you will be prompted to enter your actual
              account balance. Carefully type in the correct balance as per your
              bank statement or actual bank account details. Tap on <span>'Next'</span> to
              proceed.
            </li>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount4} alt="" />
            </div>
            <li>
              The difference between your the actual balance and the balance
              shown in "Best Budget" will be displayed and this amount will be
              posted as an adjustment transaction. Tap on <span>'Submit'</span> to confirm.
            </li>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount5} alt="" />
            </div>
            <div className="reconcile-account-section-container1-img2-div">
              <img src={ReconcileAccount6} alt="" />
            </div>
            <li>
              Your account is successfully reconciled. Tap on the 'menu' icon at
              the top right corner of the screen and select <span>'Show Reconciled'</span> to
              toggle the visibility of reconciled transactions, allowing you to
              see or hide them as needed. Alternatively, you can tap on the
              <span>'show'</span> button located at the bottom of the screen to reveal any
              hidden reconciled transactions directly.
            </li>
            <div className="reconcile-account-section-container1-img2-div">
              <img src={ReconcileAccount7} alt="" />
            </div>
            <div className="reconcile-account-section-container1-img1-div">
              <img src={ReconcileAccount8} alt="" />
            </div>
          </ol>
          <p>
            {" "}
            <strong>Note:</strong>
          </p>
          <ol type="a">
            <li>
              <strong>Multiple Reconciliations:</strong> You can reconcile an
              account multiple times. This flexibility allows you to keep your
              account balances accurate, especially if there are frequent
              updates or changes in your account transactions.
            </li>
            <li>
              <strong>Editing & Deleting Transactions:</strong> Reconciled
              transactions can still be edited if changes are required.
              Additionally, you have the option to delete a reconciled
              transaction if needed.
            </li>
            <li>
              <strong>Adding Transactions After Reconciliation:</strong> After
              completing the reconciliation process, you can still add new
              pending or cleared transactions to your account. This enables you
              to keep your account current and reflective of your actual
              financial situation.
            </li>
          </ol>
        </div>
      </div>

      <div className="learn-add-account-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ReconcileAccount;
