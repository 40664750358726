import React from "react";
import "./SearchTransactions.css";
import Search1 from "../../../Assets/Learn/Search1.webp";
import Search2 from "../../../Assets/Learn/Search2.webp";
const SearchTransactions = (props) => {
  return (
    <div className="search-transaction-main-container">
      <div className="search-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Search Transactions</p>
      </div>
      <div className="search-transaction-title-div">
        <p>How to search transactions in Best Budget</p>
      </div>
      <div className="search-transaction-section-container1">
        <div className="search-transaction-section-container1-description">
          <ol>
            <li>
              Open <span>'Accounts'</span> tab and tap on <span>'Transactions'</span> field located at
              the bottom or open the transaction details for any specific
              account.
            </li>
            <li> Tap on the search field to search the desired
              transactions.</li>
            <div className="search-transaction-section-container1-img1-div">
              <img src={Search1} alt="" />
            </div>
            <li>Type the transaction name or any relevant keyword, then press <span>'Enter'</span> on the keyboard to execute the search.</li>
            <div className="search-transaction-section-container1-img1-div">
              <img src={Search2} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default SearchTransactions;
