import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CloseAccount.css";
import CloseAccount1 from "../../../Assets/Learn/CloseAccount1.webp";
import CloseAccount2 from "../../../Assets/Learn/CloseAccount2.webp";
import CloseAccount3 from "../../../Assets/Learn/CloseAccount3.webp";
import CloseAccount4 from "../../../Assets/Learn/CloseAccount4.webp";
import CloseAccount5 from "../../../Assets/Learn/CloseAccount5.webp";
// import ReopenAccount from "./ReopenAccount";
const CloseAccount = (props) => {

  return (
    <div className="close-account-main-container">
      <div className="close-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Close Account</p>
      </div>
      <div className="close-account-title-div">
        <p>How to close an account in Best Budget</p>
      </div>
      <div className="close-account-section-container1">
        <div className="close-account-section-container1-description">
          <ol type="1">
            <li>
              Navigate to the <span>'Accounts'</span> tab and locate the specific account you
              want to close and Tap on the Account field (where the account name
              and details are displayed) to open the Account details page.
            </li>
            <div className="close-account-section-container1-img1-div">
              <img src={CloseAccount1} alt="" />
            </div>
            <li>
              On the Account Details page, look for the <span>'Menu'</span> icon in the top
              right corner (usually represented by three horizontal dots) and
              Tap on the <span>'Menu'</span> icon to reveal additional options.
            </li>
            <li>From the drop-down menu, select <span>'Close Account'</span>.</li>
            <div className="close-account-section-container1-img1-div">
              <img src={CloseAccount2} alt="" />
            </div>
            <li>
              A confirmation window will pop-up. Review the details and tap on
              the <span>'Close'</span> button to confirm the closure of the account.
            </li>
            <div className="close-account-section-container1-img1-div">
              <img src={CloseAccount3} alt="" />
            </div>
            <div className="close-account-section-container1-img1-div">
              <img src={CloseAccount4} alt="" />
            </div>
            <li>
              Your account will be temporarily closed, and all associated data
              will be archived. You can{" "}
              <span
                onClick={() => {
                  props.handleNavigation({ path: "/learn/learning-center/accounts-reopen-account", module: "Accounts" });
                }}
                style={{ color: "#2B24D8", textDecoration: "underline", cursor: "pointer" }}
              >
                reopen any closed account
              </span>{" "}
              from the closed accounts list by navigating to <span>'Accounts'</span> tab and
              select <span>'Closed Accounts'</span> from the menu located at the top left.
            </li>
            <div className="close-account-section-container1-img1-div">
              <img src={CloseAccount5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-add-account-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>

  );
};

export default CloseAccount;
