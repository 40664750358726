import React from "react";
import "./TopCharts.css";
import Summary1 from "../../../Assets/Learn/Summary1.webp";
import TopSpenders2 from "../../../Assets/Learn/TopSpenders2.webp";
import TopSpenders3 from "../../../Assets/Learn/TopSpenders3.webp";
import TopSpenders4 from "../../../Assets/Learn/TopSpenders4.webp";
const TopCharts = (props) => {
  return (
    <div className="top-charts-main-container">
      <div className="top-charts-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Top Charts</p>
      </div>
      <div className="top-charts-title-div">
        <p>Accessing 'Top charts' in dashboard</p>
      </div>
      <div className="top-charts-section-container1">
        <div className="top-charts-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Dashboard'</span> icon located in the bottom navigation bar
              and open Dashboard..
            </li>
            <div className="top-charts-section-container1-img1-div">
              <img src={Summary1} alt="" />
            </div>
            <li>
              By default, the <span>Summary</span> view is displayed when you enter the
              Dashboard.
            </li>
            <li>
              To view the <span>‘Top Spenders’</span>, tap on the right arrow at the bottom
              of the screen. This section identifies your biggest expense
              categories and payees, allowing you to make informed decisions
              about your finances.
            </li>
            <div className="top-charts-section-container1-img3-div">
              <img src={TopSpenders2} alt="" />
            </div>
            <li>
              By default, Top spent categories will be visible. Tap on the
              <span>'Payees'</span> button to view the top spent payees in your budget. This
              helps you quickly identify where most of your money is being
              allocated and spent.
            </li>
            <div className="top-charts-section-container1-img3-div">
              <img src={TopSpenders3} alt="" />
            </div>
            <li>
              Switch back to 'Top spent categories' by clicking on the <span>'Categories'</span> button.
            </li>
            <div className="top-charts-section-container1-img2-div">
              <img src={TopSpenders4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default TopCharts;
