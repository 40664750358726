import React from "react";
import "./EditAccount.css";
import EditAccount1 from "../../../Assets/Learn/EditAccount1.webp";
import EditAccount2 from "../../../Assets/Learn/EditAccount2.webp";
import EditAccount3 from "../../../Assets/Learn/EditAccount3.webp";
const EditAccount = (props) => {
  return (
    <div className="edit-account-main-container">
      <div className="edit-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Edit Account</p>
      </div>
      <div className="edit-account-title-div">
        <p>How to edit an account in Best Budget</p>
      </div>
      <div className="edit-account-section-container1">
        <div className="edit-account-section-container1-description">
          <ol type="1">
            <li>
              Navigate to the <span>'Accounts'</span> tab and locate the specific account you
              want to update and tap on the Account field (where the account
              name and details are displayed) to open the Account details page.
            </li>
            <div className="edit-account-section-container1-img1-div">
              <img src={EditAccount1} alt="" />
            </div>
            <li>
              On the Account details page, look for the <span>'Menu'</span> icon in the top
              right corner (represented by three horizontal dots) and tap on the
              <span>'Menu'</span> icon to reveal additional options.
            </li>
            <li>
              From the drop-down menu, select <span>'Edit Account'</span>. This will open the
              editing interface where you can make changes to the account.
            </li>
            <div className="edit-account-section-container1-img2-div">
              <img src={EditAccount2} alt="" />
            </div>
            <li>
              You can now edit the <span>'Account name'</span>, <span>'Current Balance'</span>, and
              <span>'Account notes'</span> as needed.
              <br />
              <strong>Note:</strong> The <span>'Account Type'</span> field cannot be edited.
              If you need to change the account type, you must close the current
              account and create a new one with desired account type. This is to
              ensure data consistency and proper categorisation within Best
              Budget.
            </li>
            <li>
              Once you have made the necessary changes, tap on <span>'Save'</span> button to
              apply the updates to your account.
            </li>
            <div className="edit-account-section-container1-img3-div">
              <img src={EditAccount3} alt="" />
            </div>
          </ol>
        </div>

      </div>


      <div className="learn-add-account-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditAccount;
