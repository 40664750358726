import React, { useState, useRef, useEffect } from "react";
import "./LearningCenter.css";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../Assets/Learn/SearchIcon.svg";
import StartBudgeting from "./StartBudgeting";
import AddAccount from "./Accounts/AddAccount";
import { useLocation } from "react-router-dom";
import "./LearningTopicsSidebar.css";
import sidebarArrowIcon from "../../Assets/Learn/sidebarArrowIcon.svg";
import sidebarMinusIcon from "../../Assets/Learn/sidebarMinusIcon.svg";
import sidebarPlusIcon from "../../Assets/Learn/sidebarPlusIcon.svg";
import ResponsiveSideBarMinus from "../../Assets/Learn/ResponsiveSideBarMinus.svg";
import ResponsiveSideBarPlus from "../../Assets/Learn/ResponsiveSideBarPlus.svg";
import Footer from "../Footer";
import EditAccount from "./Accounts/EditAccount";
import CloseAccount from "./Accounts/CloseAccount";
import ReopenAccount from "./Accounts/ReopenAccount";
import ChangeBalance from "./Accounts/ChangeBalance";
import ReconcileAccount from "./Accounts/ReconcileAccount";
import RearrangeAccount from "./Accounts/RearrangeAccount";
import AddCateGroup from "./Budget/AddCateGroup";
import AddCategory from "./Budget/AddCategory";
import EditCateGroup from "./Budget/EditCateGroup";
import EditCategory from "./Budget/EditCategory";
import HideCateGroup from "./Budget/HideCateGroup";
import HideCategory from "./Budget/HideCategory";
import MoveCategory from "./Budget/MoveCategory";
import RearrangeCategories from "./Budget/RearrangeCategories";
import IndividualAllocation from "./Budget/IndividualAllocation";
import TransferAllocation from "./Budget/TransferAllocation";
import AllocBasedOnPrevStats from "./Budget/AllocBasedOnPrevStats";
import CollectiveAllocation from "./Budget/CollectiveAllocation";
import DeleteCateGroup from "./Budget/DeleteCateGroup";
import DeleteCategory from "./Budget/DeleteCategory";
import AddIncome from "./Transactions/AddIncome";
import AddExpense from "./Transactions/AddExpense";
import MakeTransfer from "./Transactions/MakeTransfer";
import AddRecurrTransaction from "./Transactions/AddRecurrTransaction";
import FilterTransactions from "./Transactions/FilterTransactions";
import SearchTransactions from "./Transactions/SearchTransactions";
import DeleteTransactions from "./Transactions/DeleteTransactions";
import Summary from "./Dashboard/Summary";
import Allocations from "./Dashboard/Allocations";
import TopCharts from "./Dashboard/TopCharts";
import Upcoming from "./Dashboard/Upcoming";
import SpendAnalysis from "./Reports/SpendAnalysis";
import IncomeVsExpense from "./Reports/IncomeVsExpense";
import AddPayee from "./Payees/AddPayee";
import EditPayee from "./Payees/EditPayee";
import SearchPayee from "./Payees/SearchPayee";
import BudgetSettings from "./Budget/BudgetSettings";
import EditProfileDetails from "./Profile/EditProfileDetails";
import ChangeEmail from "./Profile/ChangeEmail";
import DeleteAccount from "./Profile/DeleteAccount";
import searchCloseButton from "../../Assets/Learn/searchCloseButton.svg";
import smallSearchIcon from "../../Assets/Learn/smallSearchIcon.svg";
import TurnOnMpin from "./Profile/TurnOnMpin";
import ChangeMpin from "./Profile/ChangeMpin";
import TurnOffMpin from "./Profile/TurnOffMpin";
import GetSupport from "./Profile/GetSupport";

const LearningCenter = (props) => {
  // console.log(props);
  const scrollToTop = props.scrollToTop;
  const location = useLocation();
  const pathData = location.state;
  const [currentComponentPathName, setCurrentComponentPathName] = useState(
    pathData.path ? pathData.path : ""
  );
  const navigate = useNavigate();

  const sidebarData = [
    {
      navbarListGroupName: "Get Started",
      path: "/learn/learning-center/get-started-start-budgeting",
      subList: [
        {
          navbarListName: "Start Budgeting",
          path: "/learn/learning-center/get-started-start-budgeting",
          title: "Getting started with Best Budget",
        },
      ],
    },
    {
      navbarListGroupName: "Accounts",
      path: "/learn/learning-center/accounts-add-account",
      subList: [
        {
          navbarListName: "Add Account",
          path: "/learn/learning-center/accounts-add-account",
          title: "How to add an account (Unlinked) in Best Budget",
        },
        {
          navbarListName: "Edit Account",
          path: "/learn/learning-center/accounts-edit-account",
          title: "How to edit an account in Best Budget",
        },
        {
          navbarListName: "Close Account",
          path: "/learn/learning-center/accounts-close-account",
          title: "How to close an account in Best Budget",
        },
        {
          navbarListName: "Re-open Account",
          path: "/learn/learning-center/accounts-reopen-account",
          title: "How to Reopen a closed account in Best Budget",
        },
        {
          navbarListName: "Change Balance",
          path: "/learn/learning-center/accounts-change-balance",
          title: "How to change my Account Balance in Best Budget",
        },
        {
          navbarListName: "Re-concile Account",
          path: "/learn/learning-center/accounts-reconcile-account",
          title: "How to Reconcile an account in Best Budget",
        },
        {
          navbarListName: "Re-arrange Account",
          path: "/learn/learning-center/accounts-rearrange-account",
          title: "How to re-arrange an account in Best Budget",
        },
      ],
    },
    {
      navbarListGroupName: "Budget",
      path: "/learn/learning-center/budget-plan",
      subList: [
        {
          navbarListName: "Budget Plan",
          path: "/learn/learning-center/budget-plan",
          title: "How to add a new ‘Budget Plan’ in Best Budget",
        },
        {
          navbarListName: "Add Category Group",
          path: "/learn/learning-center/budget-add-category-group",
          title: "How to add a Category Group in Best Budget",
        },

        {
          navbarListName: "Add Category",
          path: "/learn/learning-center/budget-add-category",
          title: "How to add a Category in Best Budget",
        },
        {
          navbarListName: "Edit Category Group",
          path: "/learn/learning-center/budget-edit-category-group",
          title: "How to Edit a Category Group in Best Budget",
        },
        {
          navbarListName: "Edit Category",
          path: "/learn/learning-center/budget-edit-category",
          title: "How to edit a Category in Best Budget",
        },
        {
          navbarListName: "Hide Category Group",
          path: "/learn/learning-center/budget-hide-category-group",
          title: "How to Hide a Category group in Best Budget",
        },
        {
          navbarListName: "Hide Category",
          path: "/learn/learning-center/budget-hide-category",
          title: "How to Hide a Category in Best Budget",
        },
        {
          navbarListName: "Move Category",
          path: "/learn/learning-center/budget-move-category",
          title: "How to move a Category from one category group to another",
        },
        {
          navbarListName: "Re-Arrange Categories",
          path: "/learn/learning-center/budget-rearrange-categories",
          title:
            "Rearranging categories within the category group in Best Budget",
        },
        {
          navbarListName: "Individual Allocation",
          path: "/learn/learning-center/budget-individual-allocation",
          title: "How to allocate amount to a category in Best Budget",
        },
        {
          navbarListName: "Transfer Allocation",
          path: "/learn/learning-center/budget-transfer-allocation",
          title: "Transfer amount from one category to another",
        },
        {
          navbarListName: "Allocate based on past statistics",
          path: "/learn/learning-center/budget-allocate-based-on-past-statistics",
          title: "Allocate based on past statistics in Best Budget",
        },
        {
          navbarListName: "Collective Allocation",
          path: "/learn/learning-center/budget-collective-allocation",
          title: "How to allocate all categories collectively in Best Budget",
        },
        {
          navbarListName: "Delete Category Group",
          path: "/learn/learning-center/budget-delete-category-group",
          title: "How to Delete a category group in Best Budget",
        },
        {
          navbarListName: "Delete Category",
          path: "/learn/learning-center/budget-delete-category",
          title: "How to Delete a category in Best Budget",
        },
      ],
    },
    {
      navbarListGroupName: "Transactions",
      path: "/learn/learning-center/transactions-add-income",
      subList: [
        {
          navbarListName: "Add Income",
          path: "/learn/learning-center/transactions-add-income",
          title: "How to add an income transaction in Best Budget",
        },
        {
          navbarListName: "Add Expense",
          path: "/learn/learning-center/transactions-add-expense",
          title: "How to add an expense transaction in Best Budget",
        },
        {
          navbarListName: "Make a Transfer",
          path: "/learn/learning-center/transactions-make-transfer",
          title: "How to add an transfer transaction in Best Budget",
        },
        {
          navbarListName: "Add a Recurring Transaction",
          path: "/learn/learning-center/transactions-add-recurring-transaction",
          title: "How to add a Recurring Transaction in Best Budget",
        },
        {
          navbarListName: "Filter Transactions",
          path: "/learn/learning-center/transactions-filter-transactions",
          title: "How to Filter Transactions in Best Budget",
        },
        {
          navbarListName: "Search Transactions",
          path: "/learn/learning-center/transactions-search-transactions",
          title: "How to Search Transactions in Best Budget",
        },
        {
          navbarListName: "Delete Transactions",
          path: "/learn/learning-center/transactions-delete-transactions",
          title: "How to delete a transaction in Best Budget",
        },
      ],
    },
    {
      navbarListGroupName: "Dashboard",
      path: "/learn/learning-center/dashboard-summary",
      subList: [
        {
          navbarListName: "Summary",
          path: "/learn/learning-center/dashboard-summary",
          title: "Summary in Dashboard",
        },
        {
          navbarListName: "Allocations",
          path: "/learn/learning-center/dashboard-allocations",
          title: "Allocations in Dashboard",
        },
        {
          navbarListName: "Top Spenders",
          path: "/learn/learning-center/dashboard-top-spenders",
          title: "Top Charts in Dashboard",
        },
        {
          navbarListName: "Upcoming",
          path: "/learn/learning-center/dashboard-upcoming",
          title: "Upcoming Transactions in Dashboard",
        },
      ],
    },
    {
      navbarListGroupName: "Reports",
      path: "/learn/learning-center/reports-spending-analysis",
      subList: [
        {
          navbarListName: "Spending Analysis",
          path: "/learn/learning-center/reports-spending-analysis",
          title: "Spending Analysis report in Best Budget",
        },
        {
          navbarListName: "Networth",
          path: "/learn/learning-center/reports-networth",
          title: "Income Vs Expense report in Best Budget",
        },
      ],
    },
    {
      navbarListGroupName: "Payees",
      path: "/learn/learning-center/payees-add-payee",
      subList: [
        {
          navbarListName: "Add Payee",
          path: "/learn/learning-center/payees-add-payee",
          title: "How to add a new payee in Best Budget",
        },
        {
          navbarListName: "Edit Payee",
          path: "/learn/learning-center/payees-edit-payee",
          title: "How to edit payee in Best Budget",
        },
        {
          navbarListName: "Search Payee",
          path: "/learn/learning-center/payees-search-payee",
          title: "How to search for a payee in Best Budget",
        },
      ],
    },
    // {
    //   navbarListGroupName: "Settings",
    //   path: "/learn/learning-center/settings-budget-settings",
    //   subList: [
    //     {
    //       navbarListName: "Budget Settings",
    //       path: "/learn/learning-center/settings-budget-settings",
    //       title: "How to change Budget settings in Best Budget",
    //     },
    //   ],
    // },
    {
      navbarListGroupName: "Profile",
      path: "/learn/learning-center/profile-edit-profile-details",
      subList: [
        {
          navbarListName: "Edit Profile details",
          path: "/learn/learning-center/profile-edit-profile-details",
          title: "How to Edit Profile details in Best Budget",
        },
        {
          navbarListName: "Change Email",
          path: "/learn/learning-center/profile-change-email",
          title: "How to change an email in Best Budget",
        },
        {
          navbarListName: "Delete Account",
          path: "/learn/learning-center/profile-delete-account",
          title: "How to delete an account in Best Budget",
        },

        {
          navbarListName: "Turn On MPIN",
          path: "/learn/learning-center/profile-turn-on-mpin",
          title: "How to Turn On MPIN in Best Budget",
        },
        {
          navbarListName: "Change MPIN",
          path: "/learn/learning-center/profile-change-mpin",
          title: "How to change MPIN in Best Budget",
        },
        {
          navbarListName: "Turn Off MPIN",
          path: "/learn/learning-center/profile-turn-off-mpin",
          title: "How to Turn Off MPIN in Best Budget",
        },
        {
          navbarListName: "Get Support",
          path: "/learn/learning-center/profile-get-support",
          title: "How to Get Support in Best Budget",
        },
      ],
    },
  ];
  const [expandedGroup, setExpandedGroup] = useState(
    pathData.module ? pathData.module : ""
  );
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [toggleResponsiveSideBarMenu, setToggleResponsiveSideBarMenu] =
    useState(false);
  const sidebarRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResultsObject, setSearchResultsObject] = useState([]);
  const handleExpandCollapse = (groupName) => {
    if (expandedGroup === groupName) {
      // If the clicked group is already expanded, collapse it
      setExpandedGroup(null);
    } else {
      // Otherwise, expand the clicked group and collapse others
      setExpandedGroup(groupName);
    }
  };
  const handleNavigation = ({ module, path }) => {
    setShowSearchResults(false);
    setExpandedGroup(module);
    setCurrentComponentPathName(path);
    setSearchTerm("");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };

  const handleSearchInput = (e) => {
    let term = e.target.value;
    setSearchTerm(term);
    if (term && term.length > 0) {
      setShowSearchResults(true);
    }
    handleSearchLearn(term);
    // console.log("Handle Search Input",term);
  };

  const handleSearchLearn = (searchTerm) => {
    let resultData = [];
    for (const item of sidebarData) {
      for (const subItem of item.subList) {
        if (subItem.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          resultData.push(item);
          break;
        }
      }
    }
    setSearchResultsObject(resultData);
    // setCurrentComponentPathName("/learn/learning-center/search-results");
    // console.log(resultData);
  };
  const handleCancelSearch = () => {
    setSearchTerm("");
    setCurrentComponentPathName(
      "/learn/learning-center/get-started-start-budgeting"
    );
    setShowSearchResults(false);
    setExpandedGroup("Get Started");
  };
  const scrollToMiddle = () => {
    window.scrollTo({
      top: 1900,
      behavior: "smooth",
    });
  };



  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setToggleResponsiveSideBarMenu(false);
    }
  };

  useEffect(() => {
    if (toggleResponsiveSideBarMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [toggleResponsiveSideBarMenu]);
  return (
    <React.Fragment>
      <div className="learning-center-main-container">
        <div className="learning-center-actual-section-container">
          <div className="lc-section-container1">
            <div className="lc-section-container1-title">
              <p
                onClick={() => {
                  navigate("/learn");
                  props.scrollToTop();
                }}
              >
                Learning Center
              </p>
            </div>
            <div className="lc-section-container1-content">
              <div
                className={`search-container ${searchTerm.length > 0
                  ? "active-search-container"
                  : "inactive-search-container"
                  }`}
              >
                <img
                  alt=""
                  src={searchIcon}
                  onClick={() => {
                    handleSearchLearn(searchTerm);
                  }}
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm ? searchTerm : ""}
                  onChange={(e) => {
                    handleSearchInput(e);
                  }}
                // onKeyDown={(e) => {
                //   handleKeyPress(e);
                // }}
                />
                {searchTerm.length > 0 && searchTerm ? (
                  <img
                    alt=""
                    src={searchCloseButton}
                    className="search-close-button"
                    onClick={() => {
                      handleCancelSearch();
                    }}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="last-updated-div">
                <p>Last Updated on November 1, 2024</p>
                <p>
                  Need Help,{" "}
                  <a
                    onClick={() => {
                      navigate("/about-us");
                      scrollToMiddle();
                    }}
                  >
                    Contact Us
                  </a>
                </p>
              </div>
            </div>
            <div className="lc-section-container1-search-content-div">
              {showSearchResults &&
                searchResultsObject.length > 0 &&
                searchTerm.length > 0 ? (
                <div className="lc-section-container1-search-results">
                  <div className="search-results-items-container">
                    {searchResultsObject.map((item, index) => {
                      return (
                        <div key={index}>
                          {item.subList.map((subitem, index) => {
                            if (
                              subitem.title
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="search-results-item"
                                >
                                  <div
                                    className="search-results-item-title"
                                    onClick={() => {
                                      handleNavigation({
                                        module: item.navbarListGroupName,
                                        path: subitem.path,
                                      });
                                    }}
                                  >
                                    <img alt="" src={smallSearchIcon} />
                                    <p>{subitem.title}</p>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="lc-section-container2">

            {window.innerWidth > 430 ? (
              /*web size sidebar menu */
              <div className="lc-section-container2-sidebar">
                <div className="sidebar-container">
                  <div className="sidebar-title-div">
                    <p>Learning Topics</p>
                  </div>
                  <div className="sidebar-menu-list">
                    {sidebarData &&
                      sidebarData.map((navItem, index) => {
                        return (
                          <div key={index} className="navbar-list-group">
                            <div
                              className="navbar-list-group-title"
                              onClick={() => {
                                handleExpandCollapse(
                                  navItem.navbarListGroupName
                                );
                                handleNavigation({
                                  path: navItem.path,
                                  module: navItem.navbarListGroupName,
                                });
                              }}
                            >
                              <img
                                alt=""
                                className={
                                  expandedGroup === navItem.navbarListGroupName
                                    ? "expand-collapse img-minus"
                                    : "expand-collapse img-plus"
                                }
                                src={
                                  expandedGroup === navItem.navbarListGroupName
                                    ? sidebarMinusIcon
                                    : sidebarPlusIcon
                                }
                              />
                              <p>{navItem.navbarListGroupName}</p>
                              <img
                                alt=""
                                className={
                                  expandedGroup === navItem.navbarListGroupName
                                    ? "arrow-right-display"
                                    : "arrow-right-not-display"
                                }
                                src={sidebarArrowIcon}
                              />
                            </div>
                            <div
                              className={
                                expandedGroup === navItem.navbarListGroupName
                                  ? "sub-list-display"
                                  : "sub-list-not-display"
                              }
                            >
                              {navItem.subList &&
                                navItem.subList.map((subNavItem, subIndex) => {
                                  return (
                                    <div
                                      key={subIndex}
                                      className="navbar-list-item"
                                      onClick={() => {
                                        //   console.log(props.currentComponentPathName, 124);
                                        handleNavigation({
                                          path: subNavItem.path,
                                          module: navItem.navbarListGroupName,
                                        });
                                      }}
                                    >
                                      <p
                                        className={
                                          expandedGroup ===
                                            navItem.navbarListGroupName &&
                                            subNavItem.path ===
                                            currentComponentPathName
                                            ? "active-sub-nav-item"
                                            : "inactive-sub-nav-item"
                                        }
                                      >
                                        {subNavItem.navbarListName}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <div ref={sidebarRef}
                className={`responsive-sidebar-div ${toggleResponsiveSideBarMenu
                  ? "opened-sidebar"
                  : "closed-sidebar"
                  }`}
              >
                <div
                  className={`lc-section-container2-sidebar ${toggleResponsiveSideBarMenu
                    ? "opened-sidebar-container"
                    : "closed-sidebar-container"
                    }`}
                >
                  <div className="sidebar-container">
                    <div className="sidebar-title-div">
                      <p>Learning Topics</p>
                    </div>
                    <div className="sidebar-menu-list">
                      {sidebarData &&
                        sidebarData.map((navItem, index) => {
                          return (
                            <div key={index} className="navbar-list-group">
                              <div
                                className="navbar-list-group-title"
                                onClick={() => {
                                  handleExpandCollapse(
                                    navItem.navbarListGroupName
                                  );
                                  // handleNavigation({
                                  //   path: navItem.path,
                                  //   module: navItem.navbarListGroupName,
                                  // });
                                  // setToggleResponsiveSideBarMenu(false);
                                }}
                              >
                                <img
                                  alt=""
                                  className={
                                    expandedGroup ===
                                      navItem.navbarListGroupName
                                      ? "expand-collapse img-minus"
                                      : "expand-collapse img-plus"
                                  }
                                  src={
                                    expandedGroup ===
                                      navItem.navbarListGroupName
                                      ? sidebarMinusIcon
                                      : sidebarPlusIcon
                                  }
                                />
                                <p>{navItem.navbarListGroupName}</p>
                                <img
                                  alt=""
                                  className={
                                    expandedGroup ===
                                      navItem.navbarListGroupName
                                      ? "arrow-right-display"
                                      : "arrow-right-not-display"
                                  }
                                  src={sidebarArrowIcon}
                                />
                              </div>
                              <div
                                className={
                                  expandedGroup === navItem.navbarListGroupName
                                    ? "sub-list-display"
                                    : "sub-list-not-display"
                                }
                              >
                                {navItem.subList &&
                                  navItem.subList.map(
                                    (subNavItem, subIndex) => {
                                      return (
                                        <div
                                          key={subIndex}
                                          className="navbar-list-item"
                                          onClick={() => {
                                            // handleExpandCollapse(
                                            //   navItem.navbarListGroupName
                                            // );
                                            handleNavigation({
                                              path: subNavItem.path,
                                              module:
                                                navItem.navbarListGroupName,
                                            });
                                            setToggleResponsiveSideBarMenu(
                                              false
                                            );
                                          }}
                                        >
                                          <p
                                            className={
                                              expandedGroup ===
                                                navItem.navbarListGroupName &&
                                                subNavItem.path ===
                                                currentComponentPathName
                                                ? "active-sub-nav-item"
                                                : "inactive-sub-nav-item"
                                            }
                                          >
                                            {subNavItem.navbarListName}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div
                  className={`toggle-sidebar-responsive ${!toggleResponsiveSideBarMenu ? "plus" : "minus"
                    }`}
                >
                  <img
                    alt=""
                    src={
                      !toggleResponsiveSideBarMenu
                        ? ResponsiveSideBarPlus
                        : ResponsiveSideBarMinus
                    }
                    onClick={() => {
                      setToggleResponsiveSideBarMenu(
                        !toggleResponsiveSideBarMenu
                      );
                    }}
                  />
                </div>
              </div>
            )}

            <div className="lc-section-container2-content-box">
              {/* {renderSubComponent()} */}

              {currentComponentPathName ===
                "/learn/learning-center/get-started-start-budgeting" && (
                  <StartBudgeting scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-add-account" && (
                  <AddAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-edit-account" && (
                  <EditAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-close-account" && (
                  <CloseAccount scrollToTop={scrollToTop} handleNavigation={handleNavigation} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-reopen-account" && (
                  <ReopenAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-change-balance" && (
                  <ChangeBalance scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-reconcile-account" && (
                  <ReconcileAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/accounts-rearrange-account" && (
                  <RearrangeAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-plan" && (
                  <BudgetSettings scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-add-category-group" && (
                  <AddCateGroup scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-add-category" && (
                  <AddCategory scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-edit-category-group" && (
                  <EditCateGroup scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-edit-category" && (
                  <EditCategory scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-hide-category-group" && (
                  <HideCateGroup scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-hide-category" && (
                  <HideCategory scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-move-category" && (
                  <MoveCategory scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-rearrange-categories" && (
                  <RearrangeCategories scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-individual-allocation" && (
                  <IndividualAllocation scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-transfer-allocation" && (
                  <TransferAllocation scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-allocate-based-on-past-statistics" && (
                  <AllocBasedOnPrevStats scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-collective-allocation" && (
                  <CollectiveAllocation scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-delete-category-group" && (
                  <DeleteCateGroup scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/budget-delete-category" && (
                  <DeleteCategory scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-add-income" && (
                  <AddIncome scrollToTop={scrollToTop} handleNavigation={handleNavigation} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-add-expense" && (
                  <AddExpense scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-make-transfer" && (
                  <MakeTransfer scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-add-recurring-transaction" && (
                  <AddRecurrTransaction scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-filter-transactions" && (
                  <FilterTransactions scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-search-transactions" && (
                  <SearchTransactions scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/transactions-delete-transactions" && (
                  <DeleteTransactions scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/dashboard-summary" && (
                  <Summary scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/dashboard-allocations" && (
                  <Allocations scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/dashboard-top-spenders" && (
                  <TopCharts scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/dashboard-upcoming" && (
                  <Upcoming scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/reports-spending-analysis" && (
                  <SpendAnalysis scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/reports-networth" && (
                  <IncomeVsExpense scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/payees-add-payee" && (
                  <AddPayee scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/payees-edit-payee" && (
                  <EditPayee scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/payees-search-payee" && (
                  <SearchPayee scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-edit-profile-details" && (
                  <EditProfileDetails scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-change-email" && (
                  <ChangeEmail scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-delete-account" && (
                  <DeleteAccount scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-turn-on-mpin" && (
                  <TurnOnMpin scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-change-mpin" && (
                  <ChangeMpin scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-turn-off-mpin" && (
                  <TurnOffMpin scrollToTop={scrollToTop} />
                )}
              {currentComponentPathName ===
                "/learn/learning-center/profile-get-support" && (
                  <GetSupport scrollToTop={scrollToTop} />
                )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default LearningCenter;
