import React from "react";
import "./CollectiveAllocation.css";
import CollectiveAllocation1 from "../../../Assets/Learn/CollectiveAllocation1.webp";
import CollectiveAllocation2 from "../../../Assets/Learn/CollectiveAllocation2.webp";
const CollectiveAllocation = (props) => {
  return (
    <div className="collective-allocation-main-container">
      <div className="collective-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Collective Allocation</p>
      </div>
      <div className="collective-allocation-title-div">
        <p>How to allocate all categories collectively in Best Budget</p>
      </div>
      <div className="collective-allocation-section-container1">
        <div className="collective-allocation-section-container1-description">
          <ol>
            <li>Navigate to <span>'Budget'</span> tab and tap on <span>“Allocate”</span> button.</li>
            <div className="collective-allocation-section-container1-img1-div">
              <img src={CollectiveAllocation1} alt="" />
            </div>
            <li>
              Choose from the provided options such as <span>'Average Spent',
                'Allocated Last Month',</span> or <span>'Spent Last Month'</span> to allocate the
              amount to all category groups and categories collectively, based
              on previous statistics.
            </li>
            <li>
              Tap on the <span>'Allocate'</span> option to confirm and execute the allocation.
            </li>
            <div className="collective-allocation-section-container1-img2-div">
              <img src={CollectiveAllocation2} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default CollectiveAllocation;
