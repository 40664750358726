import React, { useState, useEffect, useCallback } from "react";
import "./Pricing.css";
import rightHand from "../Assets/Pricing/rightHand.svg";
import leftHand from "../Assets/Pricing/leftHand.svg";
import womanHoldingFiles from "../Assets/Pricing/womanHoldingFiles.svg";
import shieldIcon from "../Assets/Pricing/shieldIcon.svg";
import keyIcon from "../Assets/Pricing/keyIcon.svg";
import bbLogo from "../Assets/Pricing/bbLogo.svg";
import noAdIcon from "../Assets/Pricing/noAdIcon.png";
import LaptopCodeIcon from "../Assets/Pricing/LaptopCodeIcon.png";
import manBesideBlackBoard from "../Assets/Pricing/manBesideBlackBoard.svg";
import dropDownIcon from "../Assets/Pricing/dropDownIcon.svg";
import dropUpIcon from "../Assets/Pricing/dropUpIcon.svg";
import quickSearchTransaction from "../Assets/Pricing/quickSearchTransaction.svg";
import multipleCurrencies from "../Assets/Pricing/multipleCurrencies.svg";
import networthTracking from "../Assets/Pricing/networthTracking.svg";
import dashboard from "../Assets/Pricing/dashboard.svg";
import nextSlideIcon from "../Assets/BestBudgetWay/nextSlideIcon.svg";
import prevSlideIcon from "../Assets/BestBudgetWay/prevSlideIcon.svg";
import footerImage from "../Assets/Pricing/footerImage.webp";
import Footer from "./Footer";
import SEO from "./SEO";


const Pricing = (props) => {
  const [currentTile, setCurrentTile] = useState(0);
  const [currentFaq, setCurrentFaq] = useState(0);
  const sectionFourSlides = [
    {
      title: "Quick Transaction Search",
      img: quickSearchTransaction,
      altText:
        "A man using a magnifying glass to search for a specific transaction in Best Budget personal finance application",
    },
    {
      title: "Multiple Currencies",
      img: multipleCurrencies,
      altText:
        "A person saving money by putting various currencies into a piggybank on Best Budget app.",
    },
    {
      title: "Net worth Tracking",
      img: networthTracking,
      altText:
        "A cartoon illustration of a man and a woman standing next to a graph representing the net worth of a user using Best Budget personal finance application.",
    },
    {
      title: "Dashboard",
      img: dashboard,
      altText:
        "Man in professional attire examining financial data on charts and graphs, utilizing Best Budget personal finance tool.",
    },
  ];
  const FaqDivs = [
    {
      faqId: 1,
      question: "How Secure is Best Budget ?",
      answerDescription:
        "Ensuring the safety of your data and personal information is our top priority. We dedicate significant effort to maintaining the security of your data, striving to guarantee its protection at all times.",
    },
    {
      faqId: 2,
      question: "How does the billing work in Best Budget ?",
      answerDescription:
        "After you finish the free trail you have to opt for either monthly or yearly subscription which can be purchased with in the app through google play billing.",
    },
    {
      faqId: 3,
      question:
        "Does Best Budget Support Multiple Currencies and Date formats?",
      answerDescription:
        "Yes, Best budget is equipped to support multiple currencies, date formats and Decimal notations. You can switch to any format any time without any restriction.",
    },
    {
      faqId: 4,
      question: "Why Best Budget is not free ?",
      answerDescription:
        "We firmly believe that pricing our well-crafted product fairly is one of the most transparent and sustainable approaches to conducting business.. We want to make money only from what you pay to us from monthly or annual subscriptions, ensuring full transparency and your complete understanding of the process.",
    },
    {
      faqId: 5,
      question: "Does my data sync to a new device ?",
      answerDescription:
        "Yes, your data is automatically synced when you switch to a new device without any hassle. More to that , you can login to 3 devices at a given moment.",
    },
    {
      faqId: 6,
      question: "How to report an issue or suggestion ?",
      answerDescription:
        "Navigate to the about us page and submit your query or concerns, we will respond to you within 24 Hrs. Alternatively you can write to us at 'support@bestbudget.in'",
    },
    // {
    //   faqId: 7,
    //   question: "How does the billing work ?",
    //   answerDescription:
    //     "Best Budget has all the features you need to power your financial productivity. Come join us to budget , plan, grow and flourish!",
    // },
    // {
    //   faqId: 8,
    //   question: "How does the billing work ?",
    //   answerDescription:
    //     "Best Budget has all the features you need to power your financial productivity. Come join us to budget , plan, grow and flourish!",
    // },
    // {
    //   faqId: 9,
    //   question: "How does the billing work ?",
    //   answerDescription:
    //     "Best Budget has all the features you need to power your financial productivity. Come join us to budget , plan, grow and flourish!",
    // },
  ];
  const handleExpandFaq = (id) => {
    setCurrentFaq(id);
  };
  const handleCollapseFaq = () => {
    console.log("Collapse");
    setCurrentFaq(0);
  };
 

  const nextSection4Slide = useCallback(() => {
    setCurrentTile((prevSlide) => (prevSlide + 1) % sectionFourSlides.length);
  }, [sectionFourSlides.length]);

  const prevSection4Slide = () => {
    setCurrentTile(
      (prevSlide) =>
        (prevSlide - 1 + sectionFourSlides.length) % sectionFourSlides.length
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSection4Slide();
    }, 2500);

    return () => {
      clearInterval(intervalId);
    };
  }, [nextSection4Slide]);

  return (
    <React.Fragment>
      <SEO
        title="Pricing"
        description="Get a hands on and experience the potential of Best Budget with 37 days free trail. If you like it then only subscribe."
      />
      <div className="pricing-main-container">
        <div className="pricing-section-container1">
          <div className="pricing-section-container1-bottom-layer"></div>
          <div className="pricing-section-container1-upper-layer">
            <div className="pricing-section-container1-title">
              <p>Gain piece of Mind</p>
            </div>
            <div className="pricing-section-container1-content">
              <div className="test-drive-first-div">
                <div className="test-drive-first-title">
                  <p>Test drive first.</p>
                </div>
                <div className="test-drive-first-description">
                  <p>
                    Get a hands on and experience the potential of Best Budget
                    with 21 days free trail. If you like it then only subscribe.
                  </p>
                </div>
                <div className="pricing-start-free-trial-div" onClick={() => {
                    props.openExternalLink("https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en");
                  }}>
                  <p>Start Free Trial</p>
                </div>
                <div className="pricing-test-drive-first-note">
                  <p>
                    Note: Card information is not required to start free trial.
                  </p>
                </div>
                <div className="pricing-test-drive-mobile-screen-div">
                  <div className="pricing-test-drive-dashboard-image"></div>
                </div>
              </div>
              <div className="subscription-plans-div">
                <div className="subscription-plans-title">
                  <p>Subscription Plans</p>
                </div>
                <div className="saving-div">
                  <img className="right-hand" src={rightHand} alt="" />
                  <p className="saving">
                    ₹ 510 Saving
                  </p>
                  <img className="left-hand" src={leftHand} alt="" />
                </div>
                <div className="yearly-subscription-div">
                  <div className="yearly-subscription-price">
                    <div className="yearly-subscription-price-div1">
                      <p>
                        ₹ 1650
                      </p>
                    </div>
                    <div className="yearly-subscription-price-div2">
                      <p>Per Year</p>
                      <p>( ₹ 137 / month )</p>
                    </div>
                  </div>
                  <div className="pay-yearly">
                    <p>Pay Yearly</p>
                  </div>
                </div>
                <div className="monthly-subscription-div">
                  <div className="monthly-subscription-price">
                    <div className="monthly-subscription-price-div1">
                      <p>
                        ₹ 180
                      </p>
                    </div>
                    <div className="monthly-subscription-price-div2">
                      <p>Per month</p>
                    </div>
                  </div>
                  <div className="pay-monthly">
                    <p>Pay Monthly</p>
                  </div>
                </div>
                <div className="subscription-plans-description">
                  <p>
                    No Ads. No hidden charges. Your data is secure and private.
                    We are on a mission to build a best in class product for
                    you.
                  </p>
                </div>
                <div className="subscription-plans-footer">
                  <p>All subscriptions renew automatically, Cancel any time</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-section-container2">
          <div className="pricing-section-container2-title">
            <p>Your data security is our Top priority</p>
          </div>
          <div className="pricing-section2-content-div">
            <div className="pricing-section2-img-div">
              <img
                src={womanHoldingFiles}
                alt="A woman holds a padlock and a folder, symbolizing financial data security and organization with the BestBudget personal finance application."
              />
            </div>
            <div className="pricing-section2-content-box">
              <div className="shield-img-div">
                <img
                  src={shieldIcon}
                  alt="BestBudget personal finance application."
                />
              </div>
              <div className="pricing-section2-content-box-part1">
                <div className="key-div">
                  <div className="key-div-img">
                    <img
                      src={keyIcon}
                      alt="Best Budget personal finance application."
                    />
                  </div>
                  <div className="key-div-description">
                    <p>Your data is protected while in transit and at rest.</p>
                  </div>
                </div>
                <div className="logo-div">
                  <div className="logo-div-img">
                    <img
                      src={bbLogo}
                      alt="Best Budget personal finance application."
                    />
                  </div>
                  <div className="logo-div-description">
                    <p>
                      Best Budget makes money with your subscriptions, not your
                      data.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pricing-section2-content-box-part2">
                <div className="no-ad-div">
                  <div className="no-ad-div-img">
                    <img
                      src={noAdIcon}
                      alt="Best Budget personal finance application."
                    />
                  </div>
                  <div className="no-ad-div-description">
                    <p>No advertisements, ever.</p>
                  </div>
                </div>
                <div className="laptop-code-div">
                  <div className="laptip-code-div-img">
                    <img
                      src={LaptopCodeIcon}
                      alt="Best Budget personal finance application."
                    />
                  </div>
                  <div className="laptop-code-div-description">
                    <p>
                      We are committed to active Development and continuous
                      improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-section-container3">
          <div className="pricing-section-container3-title">
            <p>FAQs</p>
          </div>
          <div className="pricing-section-container3-content">
            <div className="pricing-section3-bottom-layer">
              <img
                src={manBesideBlackBoard}
                alt="A man in a suit stands by a board with a clipboard, explaining FAQs to users of the Best Budget personal finance application."
              />
            </div>
            <div className="pricing-section3-top-layer">
              {FaqDivs.map((faq, index) => {
                return (
                  <div
                    key={index}
                    className={`faq-div ${
                      currentFaq === index + 1 ? "active-faq-div" : ""
                    }`}
                  >
                    <div
                      className="question-div"
                      onClick={() => {
                        currentFaq !== index + 1
                          ? handleExpandFaq(faq.faqId)
                          : handleCollapseFaq();
                      }}
                    >
                      <p>{faq.question}</p>
                      {currentFaq !== index + 1 ? (
                        <img src={dropDownIcon} alt="" />
                      ) : (
                        <img
                          src={dropUpIcon}
                          // onClick={() => {
                          //   handleCollapseFaq();
                          // }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="answer-div">
                      {currentFaq === faq.faqId && currentFaq === index + 1 ? (
                        <p>{faq.answerDescription}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pricing-section-container4">
          <div className="pricing-section-container4-content">
            <div className="pricing-section-container4-content-title">
              <p>Committed to build best for you</p>
            </div>
            <div className="pricing-section-container4-content-description">
              <p>
                Best Budget offers all the essential features to enhance your
                financial productivity. Join us to budget, plan, grow, and
                flourish!
              </p>
            </div>
            <div className="pricing-section-container4-card-tiles-div">
              <div className="card-tile-1">
                <div className="card-tile-1-img">
                  <img
                    src={quickSearchTransaction}
                    alt="A man using a magnifying glass to search for a specific transaction in Best Budget personal finance application"
                  />
                </div>
                <div className="card-tile-1-title">
                  <p>Quick Transaction Search</p>
                </div>
              </div>
              <div className="card-tile-2">
                <div className="card-tile-2-img">
                  <img
                    src={multipleCurrencies}
                    alt="A person saving money by putting various currencies into a piggybank on Best Budget app."
                  />
                </div>
                <div className="card-tile-2-title">
                  <p>Multiple Currencies</p>
                </div>
              </div>
              <div className="card-tile-3">
                <div className="card-tile-3-img">
                  <img
                    src={networthTracking}
                    alt="A cartoon illustration of a man and a woman standing next to a graph representing the net worth of a user using Best Budget personal finance application."
                  />
                </div>
                <div className="card-tile-3-title">
                  <p>Net worth Tracking</p>
                </div>
              </div>
              <div className="card-tile-4">
                <div className="card-tile-4-img">
                  <img
                    src={dashboard}
                    alt="Man in professional attire examining financial data on charts and graphs, utilizing Best Budget personal finance tool."
                  />
                </div>
                <div className="card-tile-4-title">
                  <p>Dashboard</p>
                </div>
              </div>
            </div>
            {window.innerWidth <= 430 ? (
              <div className="pricing-section-container4-carousal">
                <div
                  className="slide-btn"
                  onClick={() => {
                    prevSection4Slide();
                  }}
                >
                  <img src={prevSlideIcon} alt="" />
                </div>
                <div className="pricing-section4-carousal">
                  {sectionFourSlides &&
                    sectionFourSlides.map((slide, index) => {
                      return (
                        <div
                          key={index}
                          className={`card-tile-${index + 1} ${
                            index === currentTile ? "activeTile" : ""
                          }`}
                        >
                          <div className={`card-tile-${index + 1}-img`}>
                            <img src={slide.img} alt={slide.altText} />
                          </div>
                          <div className={`card-tile-${index + 1}-title`}>
                            <p>{slide.title}</p>
                          </div>
                        </div>
                      );
                    })}
                  <div className="section4-radio-controls">
                    {sectionFourSlides &&
                      sectionFourSlides.map((_, index) => {
                        return (
                          <div
                            key={index}
                            className={`section4-radio-container ${
                              index === currentTile ? "checked" : ""
                            }`}
                            onClick={() => setCurrentTile(index)}
                          ></div>
                        );
                      })}
                  </div>
                </div>
                <div
                  className="slide-btn"
                  onClick={() => {
                    nextSection4Slide();
                  }}
                >
                  <img src={nextSlideIcon} alt="" />
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="pricing-section2-title">
              <p>Great minds working together</p>
            </div>
            <div className="pricing-section2-description">
              <p>
                Building something like best budget isn’t easy, but we are lucky
                to have an all star team leading the way. Our team is committed
                to make an impact in people’s lives.
              </p>
              <p>
                {" "}
                We care about getting it right and about doing right by our
                users. Get in touch with us for any questions or feedback— we
                would love to hear from you.
              </p>
            </div>
            <div className="pricing-go-to-top">
              <div
                className="go-to-page-top"
                onClick={() => {
                  props.scrollToTop();
                }}
              ></div>
            </div>
            <div className="footer-image-div">
              <img
                src={footerImage}
                alt="Team collaborating around a table with a laptop, creating the Best Budget personal finance application."
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Pricing;
