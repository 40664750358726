import React from "react";
import "./DeleteTransactions.css";
import DeleteTrans1 from "../../../Assets/Learn/DeleteTrans1.webp";
import DeleteTrans2 from "../../../Assets/Learn/DeleteTrans2.webp";
import DeleteTrans3 from "../../../Assets/Learn/DeleteTrans3.webp";

const DeleteTransactions = (props) => {
  return (
    <div className="delete-transaction-main-container">
      <div className="delete-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Delete Transaction</p>
      </div>
      <div className="delete-transaction-title-div">
        <p>How to delete a transaction in Best Budget</p>
      </div>
      <div className="delete-transaction-section-container1">
        <div className="delete-transaction-section-container1-description">
          <ol>
            <li>
              Open <span>'Accounts'</span> page and tap on <span>'Transactions'</span> field located at
              the bottom or open the transaction details for any specific
              account.
            </li>
            <li>
              Tap on the checkbox next to the desired transaction you wish to
              delete.
            </li>
            <li>
              From the options below, select the <span>'Delete'</span> option to remove the
              selected transaction.
            </li>
            <div className="delete-transaction-section-container1-img3-div">
              <img src={DeleteTrans1} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="delete-transaction-title-div">
        <p>How to delete multiple transactions in Best Budget</p>
      </div>
      <div className="delete-transaction-section-container1">
        <div className="delete-transaction-section-container1-description">
          <ol>
            <li>
              To delete multiple transactions, check the checkboxes for each
              transaction you want to delete and select <span>'Delete Selected'</span>.
            </li>
            <div className="delete-transaction-section-container1-img3-div">
              <img src={DeleteTrans2} alt="" />
            </div>
            <li>
              A pop-up window will appear to confirm your action. Select
              'Delete' to permanently remove all selected transactions.
            </li>
            <div className="delete-transaction-section-container1-img3-div">
              <img src={DeleteTrans3} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteTransactions;
