import React from "react";
import "./Summary.css";
import Summary1 from "../../../Assets/Learn/Summary1.webp";
import Summary2 from "../../../Assets/Learn/Summary2.webp";
const Summary = (props) => {
  return (
    <div className="summary-main-container">
      <div className="summary-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Summary</p>
      </div>
      <div className="summary-title-div">
        <p>Accessing 'Summary' in dashboard</p>
      </div>
      <div className="summary-section-container1">
        <div className="summary-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Dashboard'</span> icon located in the bottom navigation bar
              and open Dashboard. It provides a comprehensive and
              easy-to-understand overview of your finances, allowing you to
              quickly assess your financial health and make informed decisions.
            </li>
            <div className="summary-section-container1-img1-div">
              <img src={Summary1} alt="" />
            </div>
            <li>
              The Best Budget dashboard is divided into four sections: <span>Summary,
                Allocations, Top Spenders,</span> and <span>Upcoming</span>. Use the right and left
              arrows to navigate between these sections and alternatively you
              can also swipe left or right on the screen.
            </li>
            <li>
              By default, the <span>'Summary'</span> view will be presented. This section
              provides essential insights into your current financial situation.
              This information helps you understand your current financial
              snapshot.
            </li>
            <p>
              Summary section is divided into three sub-sections:
              <ol type="a">
                <li>
                  <strong>NET WORTH:</strong> This sub-section shows your <span>Net
                    Worth</span>, calculated as the difference between your <span>Assets</span>
                  (savings, investments, and cash) and <span>Liabilities</span> (loans and
                  debts). It provides insights into your financial stability.
                </li>
                <li>
                  <strong>KEY FIGURES:</strong> Here, four key metrics help you
                  track and manage your budget effectively: <br />
                  <strong>Total Allocations:</strong> The total amount allocated
                  for spending across all categories.<br /> <strong>Spent Already:</strong> The amount
                  spent so far in the current month.<br /> <strong>Left to Spend:</strong> The
                  remaining budget available for expenses. <br /> <strong>Available to Budget:</strong>
                  Funds not yet assigned to any category, ready for allocation.
                </li>
                <li>
                  <strong>INSIGHTS:</strong> This sub-section delivers valuable
                  insights into your spending trends and budgeting progress. It
                  compares current spending with previous months, evaluates how
                  effectively you’re using your funds, and highlights changes in
                  your financial habits.
                </li>
              </ol>
            </p>
            <div className="summary-section-container1-img3-div">
              <img src={Summary2} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Summary;
