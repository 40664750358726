import React from "react";
import Footer from "./Footer";
import subscribeImage from "../Assets/EmailVerification/subscribeImage.png";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const SubscriptionPage = () => {
  const query = useQuery();

  const getCurrentDate = () => {
    const today = new Date();

    // Get year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");

    // Return formatted date
    return `${year}-${month}-${day}`;
  };
  const currentDate = getCurrentDate();
  // Get specific query params
  const paramValue = query.get("expiry-date"); // Replace 'paramName' with the actual query parameter name

  return (
    <React.Fragment>
      <div className="email-verify-main-container">
        <div className="section1-container">
          <div className="section1-content">
            <h1 className="section1-content-header">Welcome to Best Budget</h1>
            <div className="section1-content-description">
              <p> </p>

              {paramValue >= currentDate ? (
                <p>
                  Your free trial ends on <strong>{paramValue}</strong>, Please
                  Login to the Best Budget application in order to subscribe.!!
                </p>
              ) : (
                <p>
                  Your free trial got ended on <strong>{paramValue}</strong>,
                  please Login to the Best Budget application in order to
                  subscribe.!!
                </p>
              )}
            </div>
          </div>
          <div className="section1-image-container">
            <img src={subscribeImage} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default SubscriptionPage;
