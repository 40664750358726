import React from "react";
import "./Security.css";
import Footer from "../Footer";
import SEO from "../SEO";
const Security = (props) => {
  
  return (
    <React.Fragment>
     <SEO
        title="Security"
        description="Track your expenses effortlessly with our user-friendly expense tracker application. Stay on top of your finances, create budgets, and get detailed reports to make informed financial decisions. Try it now for free!."
      />
      <div className="security-main-container">
        <div className="security-actual-content-container">
          <div className="security-first-section">
            <div className="security-heading1">
              <p>Security</p>
            </div>
            <div className="security-last-updated-div">
              <p>Last updated: 1st April 2024</p>
            </div>
            <div className="security-description1">
              <p>
                If you've identified a legitimate security concern you'd like to
                bring to our attention, please reach out to us via email at
                &nbsp;
                <a href="mailto:security@bestbudget.in">
                  security@bestbudget.in
                </a>
                . It's important to note that while we welcome and value your
                input, we currently do not operate a formal bug bounty program.
              </p>
            </div>
          </div>
          <div className="security-sub-section1">
            <h3 className="security-sub-section1-title">
              1. How You're Linked with Best Budget
            </h3>
            <div className="security-sub-section1-description">
              <p>
                Your connection to Best Budget is safeguarded with encryption,
                ensuring that all communication between your device and our
                network remains private and inaccessible to unauthorized
                parties. This is particularly crucial when using Best Budget on
                public Wi-Fi networks
              </p>
              <p>
                For optimal security, we recommend using a modern device with
                the latest operating system updates.
              </p>
              <p>
                <i>Technical Notes:</i>
              </p>
              <p>
                We offer support for a variety of TLS cipher suites, aiming to
                find a compromise between security and device compatibility. The
                negotiated cipher suite might differ across devices. Older
                devices might solely support less robust cipher suites.{" "}
              </p>
              <p>
                Given our utilization of Cloudflare, TLS termination occurs at
                the network edge prior to a subsequent TLS request reaching our
                origin servers. Refer to the Cloudflare section for additional
                information.
              </p>
            </div>
          </div>
          <div className="security-sub-section2">
            <h3 className="security-sub-section2-title">
              2. Data Security Measures
            </h3>
            <div className="security-sub-section2-description">
              <p>
                Your data is securely stored with encryption while at rest. This
                ensures that even if unauthorized access to the data centre
                housing our servers were to occur, the information would remain
                inaccessible and protected from retrieval.
              </p>
              <p>
                <i>Technical Notes:</i>
              </p>
              <p>
                We opt not to encrypt fields within the database. Our
                applications require possession of the decryption key to access
                encrypted data. However, if a bad actor were to infiltrate an
                application server, obtaining the decryption key could render
                the encryption ineffective.
              </p>
              <p>
                Instead, our primary focus is on preventing unauthorized access
                from occurring in the initial stages.
              </p>
              <p>
                Your password undergoes a secure hashing process using the
                bcrypt algorithm before storage. This irreversible hash ensures
                that your actual password remains undisclosed and protected.
              </p>
            </div>
          </div>
          <div className="security-sub-section3">
            <h3 className="security-sub-section3-title">
              3. Credit card details
            </h3>
            <div className="security-sub-section3-description">
              <p>
                We prioritize the security of your credit card information. When
                you input your credit card details, we immediately transmit them
                to Google Play Billing, where they undergo tokenization. This
                process generates a unique identifier that allows us to bill
                your card for future transactions. It's important to note that
                Best Budget does not retain your credit card data; it is
                promptly disposed of once forwarded to Google Play Billing. Your
                privacy and security are central to our practices, and we ensure
                that your sensitive financial information is handled with care
                and diligence.
              </p>
            </div>
          </div>
          <div className="security-sub-section4">
            <h3 className="security-sub-section4-title">
              4. OTP Login authentication
            </h3>
            <div className="security-sub-section4-description">
              <p>
                Best Budget extends OTP (One-Time Password) login functionality
                to all users, regardless of subscription status. To access Best
                Budget, users are prompted to input an additional code generated
                by our system on their registered device. This added security
                measure enhances login authentication, ensuring a robust
                safeguard against unauthorized access.
              </p>
            </div>
          </div>
          <div className="security-sub-section5">
            <h3 className="security-sub-section5-title">5. Personnel</h3>
            <div className="security-sub-section5-description">
              <p>
                At Best Budget, access to customer data is restricted to a
                select group of employees. Our support team may occasionally
                access your account to diagnose and resolve issues promptly.
              </p>
              <p>
                However, if you prefer to restrict access to your account,
                please notify us when you contact support. Additionally, certain
                members of our engineering and operations teams have access to
                customer data to fulfil their responsibilities. Rest assured,
                all Best Budget employees are bound by strict confidentiality
                agreements to safeguard your privacy and data security.
              </p>
            </div>
          </div>
          <div className="security-sub-section6">
            <h3 className="security-sub-section6-title">
              6. Application and network security
            </h3>
            <div className="security-sub-section6-description">
              <p>
                At Best Budget, our team of talented engineers collaborates
                closely to develop and maintain our platform. We uphold a
                rigorous culture of peer review to ensure that all code meets
                the highest standards of robustness and security. Additionally,
                we proactively revisit and enhance various components of the app
                to further fortify its security measures over time.
              </p>
            </div>
          </div>
          <div className="security-sub-section7">
            <h3 className="security-sub-section7-title">7. Cloudflare</h3>
            <div className="security-sub-section7-description">
              <p>
                Best Budget leverages Cloudflare, a robust service positioned in
                front of our platform. Cloudflare enhances your Best Budget
                experience by accelerating performance and acting as a
                protective barrier against common cyber threats, effectively
                safeguarding our servers from potential attacks.
              </p>
              <p>
                <i>Technical Notes:</i>
              </p>
              <p>
                Cloudflare handles TLS termination upon the arrival of your
                request at its edge servers, initiating its own TLS request to
                our original servers. This ensures encryption between you and
                Cloudflare, as well as between Cloudflare and Best Budget.
              </p>
              <p>
                It's important to note that during the termination process,
                Cloudflare gains access to the unencrypted contents of your
                communication.
              </p>
            </div>
          </div>
          <div className="security-go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Security;
