import React from "react";
import "./AddCategory.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import AddCategory2 from "../../../Assets/Learn/AddCategory2.webp";
import AddCategory3 from "../../../Assets/Learn/AddCategory3.webp";
import AddCategory4 from "../../../Assets/Learn/AddCategory4.webp";
import AddCategory5 from "../../../Assets/Learn/AddCategory5.webp";
const AddCategory = (props) => {
  return (
    <div className="add-category-main-container">
      <div className="add-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Add Category</p>
      </div>
      <div className="add-category-title-div">
        <p>How to add a category in Best Budget</p>
      </div>
      <div className="add-category-section-container1">
        <div className="add-category-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="add-category-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Tap on the <span>'Add Category'</span> icon (represented by a plus symbol)
              located on the right side of the Category Group row.
            </li>
            <div className="add-category-section-container1-img1-div">
              <img src={AddCategory2} alt="" />
            </div>
            <li>
              Enter the desired name for the category. You can add more
              categories by tapping on the + icon of category group row.
            </li>
            <div className="add-category-section-container1-img1-div">
              <img src={AddCategory3} alt="" />
            </div>
            <li>
              Tap on <span>'SAVE'</span> button to save your new category(s). <br />
              <strong>Note:</strong> You can add new category groups and
              categories, edit existing ones, and save all changes at once by
              tapping the 'SAVE' button.
            </li>
            <div className="add-category-section-container1-img1-div">
              <img src={AddCategory4} alt="" />
            </div>
            <div className="add-category-section-container1-img1-div">
              <img src={AddCategory5} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddCategory;
